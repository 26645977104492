import React, { useEffect, useState } from 'react';
import './ChartLegend.scss';

export default function ChartLegend(props) {
  const { legendList } = props;

  return (
    <div className="ChartLegend">
      {legendList.map((item) => (
        <div className="legend-item" key={item.name}>
          <div className="legend-item-left">
            <div className="legend-color">
              <svg width="30" height="20">
                <rect width="30" height="20" style={{ fill: item.color }} />
              </svg>
            </div>
            <div className="legend-text">
              {item.name}
            </div>
          </div>
          <div className="legend-item-right">
            <div className="legend-text">
              {item.label}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}
