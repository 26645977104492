import React, { useEffect, useState } from 'react';
import ChartLegend from '../reportChartDetails/chartLegend/ChartLegend';
import { RadialChart } from 'react-vis';
import moment from 'moment';
import './PieChart.scss';

export default function PieChart(props) {
  const { title, data } = props;

  return (
    <div className="PieChart">
      <div className="title">{title}</div>
      <RadialChart
        data={data}
        width={240}
        height={240}
        colorType='literal'
        showLabels={false}
      />
      <ChartLegend legendList={data} />
    </div>
  );
}
