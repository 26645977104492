import React, { useEffect, useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { Form, Button, Row, Col, message, Upload } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import './EditFormWhatsapp.scss';
import { get, post, download, importWhatsappCsv } from '../../dataSource/data_repository';
import { HTTPCodes } from '../../utils/constants/response-codes';

export default function EditFormWhatsapp() {
  const history = useHistory();
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(true);
  const [fileWhatsappCsv, setFileWhatsappCsv] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [upload2Error, setUpload2Error] = useState(false);
  const [reportInfo, setReportInfo] = useState([]);

  const { id } = useRouteMatch().params;

  const handleSubmit = async () => {
    if (fileWhatsappCsv?.length !== 1) {
      setUpload2Error(true);
      return;
    }

    setUploading(true);

    const params = {
      reportId: id,
    };

    console.log('params ', params);

    setUploading(true);

    const whatsappCsv = fileWhatsappCsv[0];
    importWhatsappCsv({
      whatsappCsv,
      params,
    }).then((response) => {
      setUploading(false);
      if (!response.error) {
        message.success('Importação realizada com sucesso');
        // history.push('/relatorios');
      } else {
        message.error('Ocorreu um erro ao realizar a importação');
      }
    }).catch((err) => {
      console.log('err ', err);
      message.error('Ocorreu um erro, tente novamente mais tarde');
      setUploading(false);
    });
  };

  async function fetchData() {
    get({ url: `/report/info?id=${id}` }).then((response) => {
      setIsLoading(false);
      console.log('response ', response);

      if (!response.error) {
        if (response.report.type !== 'WhatsApp') {
          history.push('/relatorios');
        }
        setReportInfo(response.report);
      } else {
        message.error('Ocorreu um erro ao buscar os dados');
      }
      setIsLoading(false);
    }).catch((err) => {
      console.log('Erro ', err);
      message.error('Ocorreu um erro ao buscar os dados');
      setIsLoading(false);
    });
  }

  function handleDownloadCsv(id) {
    download({ url: `/report/downloadCsv?id=${id}` }).then((responseFile) => {

      console.log('responseFile ', responseFile);

      if (responseFile.status === HTTPCodes.SUCCESS) {
        const file = new Blob([responseFile.body], { type: 'text/csv' });

        const anchor = document.createElement('a');
        const url = window.URL.createObjectURL(file);
        anchor.href = url;
        anchor.download = `${id}`;
        document.body.append(anchor);
        anchor.click();
        setTimeout(() => {
          document.body.removeChild(anchor);
          window.URL.revokeObjectURL(url);
        }, 0);
      } else {
        message.error('Ocorreu um erro ao realizar o download');
      }
    }).catch((err) => {
      console.log('Erro ', err);
      message.error('Ocorreu um erro, tente novamente mais tarde');
    });
  }

  function generateCSVRequest() {
    setUploading(true);
    post({ url: `/report/generateWhatsappCsv?id=${id}` }).then((response) => {
      setUploading(false);
      console.log('response ', response);

      if (!response.error) {
        handleDownloadCsv(id);
      } else {
        message.error('Ocorreu ao gerar o CSV');
      }
      setUploading(false);
    }).catch((err) => {
      console.log('Erro ', err);
      message.error('Ocorreu um erro com a requisição');
      setUploading(false);
    });
  }

  useEffect(() => {
    if (isLoading) {
      fetchData();
    }
    document.title = 'Editar relatório WhatsApp';
  }, []);

  return (
    <div className="EditFormWhatsapp">
      <div className="titulo">
        Informações:
      </div>
      <Row>
        <Col xs={{ span: 24 }} lg={{ span: 8 }}>
          <b>ID: </b>{reportInfo?.id}
        </Col>
        <Col xs={{ span: 24 }} lg={{ span: 8 }}>
          <b>Empresa: </b>{reportInfo?.company?.name}
        </Col>
        <Col xs={{ span: 24 }} lg={{ span: 8 }}>
          <b>Campanha: </b>{reportInfo?.campaign}
        </Col>

        <Col xs={{ span: 24 }} lg={{ span: 8 }}>
          <b>Data: </b>{reportInfo?.date}
        </Col>
        <Col xs={{ span: 24 }} lg={{ span: 8 }}>
          <b>Quantidade: </b>{reportInfo?.quantity}
        </Col>
        <Col xs={{ span: 24 }} lg={{ span: 8 }}>
          <b>Tipo: </b>{reportInfo?.type}
        </Col>
      </Row>

      <Form
        form={form}
        className="EditFormWhatsappForm"
        onFinish={() => handleSubmit()}
        layout="vertical"
        initialValues={{
          email: '',
          company: 1,
        }}
      >
        <div className="titulo">
          Download:
        </div>
        <Row>
          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <Button
              className="btn-primary btn-action"
              type="primary"
              // loading={isLoading}
              loading={uploading}
              onClick={() => { generateCSVRequest(); }}
            >
              Gerar arquivo CSV
            </Button>
          </Col>

          <Col xs={{ span: 24 }} lg={{ span: 12 }}>

          </Col>

        </Row>

        <div className="titulo">
          Upload:
        </div>
        <Row>
          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <Upload
              onRemove={(file) => {
                const index = fileWhatsappCsv.indexOf(file);
                const newFileList = fileWhatsappCsv.slice();
                newFileList.splice(index, 1);
                setFileWhatsappCsv(newFileList);
              }}
              beforeUpload={(file) => {
                setFileWhatsappCsv([file]);
                setUpload2Error(false);
                return false;
              }}
              fileList={fileWhatsappCsv}
              accept=".csv"
            >
              <Button icon={<UploadOutlined />}>Upload arquivo CSV</Button>
            </Upload>
            { upload2Error && <div style={{ height: 20, color: '#ff4d4f' }}>O arquivo é obrigatório</div> }
          </Col>

          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <Button
              className="btn-primary btn-action"
              type="primary"
              htmlType="submit"
              // loading={isLoading}
              // loading={uploading}
            >
              Atualizar dados
            </Button>
          </Col>
        </Row>
        <div style={{ height: 40 }} />

        <div className="actions-container">
          <Button
            className="btn-secondary btn-action"
            // onClick={() => handleBack()}
            onClick={() => history.push('/relatorios')}
          >
            Voltar
          </Button>

        </div>
      </Form>

    </div>
  );
}
