import React, { useEffect, useState } from 'react';
import { message, Spin, Table } from 'antd';
import './ExampleList.scss';
import { sampleGET } from '../../../dataSource/data_repository';
import { ExampleListMessages } from '../../../assets/strings/strings';

const dataSource = [
  {
    key: '1',
    name: 'Mike',
    age: 32,
    address: '10 Downing Street',
  },
  {
    key: '2',
    name: 'John',
    age: 42,
    address: '10 Downing Street',
  },
];

const columns = [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'Age',
    dataIndex: 'age',
    key: 'age',
  },
  {
    title: 'Address',
    dataIndex: 'address',
    key: 'address',
  },
];

export default function ExampleList() {
  const [loadingData, setLoadingData] = useState(true);
  const [listData, setListData] = useState([]);

  async function fetchData() {
    sampleGET({ url: '/' }).then((response1) => {
      setLoadingData(false);
      console.log('response1 ', response1);
      const response = {
        error: false,
      };
      if (!response.error) {
        setListData(dataSource);
      } else {
        message.error(ExampleListMessages.messageErrorLoadingData);
      }
      setLoadingData(false);
    }).catch((err) => {
      console.log('Erro ', err);
      message.error(ExampleListMessages.messageGeneralRequestError);
      setLoadingData(false);
    });
  }

  useEffect(() => {
    if (loadingData) {
      fetchData();
    }
  }, []);

  return (
    <div className="ExampleList">
      <Spin
        tip={ExampleListMessages.spinLoading}
        spinning={loadingData}
      >
        <Table dataSource={listData} columns={columns} pagination={false} />
      </Spin>
    </div>
  );
}
