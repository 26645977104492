import React, { useEffect, useState } from 'react';
import {
  message,
  Spin,
  Table,
  Row,
  Col,
  Form,
  Select,
  Button,
  Popconfirm, Tooltip,
} from 'antd';
import DownloadIcon from 'mdi-react/DownloadIcon';
import PlusIcon from 'mdi-react/PlusIcon';
import TrashIcon from 'mdi-react/TrashIcon';
import RefreshIcon from 'mdi-react/RefreshIcon';
import moment from 'moment';
import { useHistory, useRouteMatch } from 'react-router-dom';
import './MonthReportList.scss';
import { download, get, del, post } from '../../../dataSource/data_repository';
import { HTTPCodes } from '../../../utils/constants/response-codes';
import { getUserPermissions } from '../../../utils/users';

const initialData = {
  month: Number(moment().subtract(1, 'months').format('MM')).toString(),
  year: Number(moment().subtract(1, 'months').format('yyyy')).toString(),
};

export default function MonthReportList() {
  const { type } = useRouteMatch().params;
  const [form] = Form.useForm();
  const history = useHistory();
  const [listCompanies, setListCompanies] = useState([]);
  const [isAdmin, setIsAdmin] = useState(true);
  const [loading, setLoading] = useState(true);
  const [refreshing, setRefreshing] = useState([]);
  const [month, setMonth] = useState(initialData.month);
  const [year, setYear] = useState(initialData.year);
  const [listData, setListData] = useState([]);
  const [paginationInfo, setPaginationInfo] = useState({
    current: 1,
    currentTotal: 0,
    pageSize: 1,
    total: 0,
  });

  function getFilterString() {
    let filterString = '';
    const monthField = form.getFieldValue('month');
    if (monthField) {
      filterString = `${filterString}&month=${monthField}`;
    }
    const yearField = form.getFieldValue('year');
    if (yearField) {
      filterString = `${filterString}&year=${yearField}`;
    }
    setMonth(monthField);
    setYear(yearField);
    return filterString;
  }

  async function fetchData(page) {
    const filterString = getFilterString();
    console.log('filterString ', filterString);
    get({ url: `/reportMonth?page=${page - 1}&type=${type}${filterString}` }).then((response) => {
      setLoading(false);
      if (!response.error) {
        const { reports } = response;
        if (isAdmin) {
          setListData(listCompanies.map((v, index) => {
            const find = reports.find((f) => (v?.id === f?.company?.id));
            if (find) {
              return find;
            }
            return {
              company: { id: v.id, name: v.name },
              companyGroup: v.companyGroup,
              fileStatus: '',
              id: `id${index}`,
              month: null,
              year: null,
            };
          }));
        } else {
          setListData(reports);
        }
      } else {
        message.error('Erro ao carregar os dados');
      }
      setLoading(false);
    }).catch((err) => {
      console.log('Erro ', err);
      message.error('Ocorreu um erro na requisição. Por favor, tente novamente mais tarde');
      setLoading(false);
    });
  }

  useEffect(() => {
    setLoading(true);
    get({ url: '/company' }).then((response) => {
      setLoading(false);
      if (!response.error) {
        console.log('response.companies ', response.companies);
        setListCompanies(response.companies);
      } else {
        message.error('Ocorreu um erro ao buscar os dados da lista de empresas');
      }
    }).catch((err) => {
      message.error('Ocorreu um erro ao buscar os dados da lista de empresas');
      setLoading(false);
    });
    if (getUserPermissions().indexOf('EDIT_SYSTEM_PARAMETER') === -1) {
      setIsAdmin(false);
    }
  }, [type]);

  useEffect(() => {
    fetchData(1);
  }, [listCompanies]);

  function handleCreate(companyId) {
    const currentObj = { year, month };
    setRefreshing([...refreshing, companyId]);
    post({
      url: `/reportMonth/generateFile?companyId=${companyId}&type=${type}&year=${year}&month=${month}`,
    }).then((response) => {
      setRefreshing(refreshing.filter((v) => v !== companyId));
      if (!response.error) {
        setListData(listData.map((v) => {
          if (v.company.id === companyId) {
            return { ...v, ...currentObj, fileStatus: 'generated' };
          }
          return v;
        }));
      } else {
        if (response.code === -9002) {
          message.warning('Esta empresa ainda não possui nenhum relatório para o mês selecionado');
        } else {
          message.error('Erro ao carregar os dados');
        }
      }
      setLoading(false);
    }).catch((err) => {
      console.log('Erro ', err);
      message.error('Ocorreu um erro na requisição. Por favor, tente novamente mais tarde');
      setRefreshing(refreshing.filter((v) => v !== companyId));
    });
  }

  async function cleanData() {
    // reset fields
    form.resetFields();
    fetchData(1);
  }

  function handleDownload(companyId, name) {
    setLoading(true);
    download({
      url: `/reportMonth/downloadByCompanyId?companyId=${companyId}&type=${type}&year=${year}&month=${month}`,
    }).then((responseFile) => {
      setLoading(false);

      console.log('responseFile ', responseFile);

      if (responseFile.status === HTTPCodes.SUCCESS) {
        const file = new Blob([responseFile.body], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

        const anchor = document.createElement('a');
        const url = window.URL.createObjectURL(file);
        anchor.href = url;
        anchor.download = `${name} ${year}-${(`0${month}`).slice(-2)}`;
        document.body.append(anchor);
        anchor.click();
        setTimeout(() => {
          document.body.removeChild(anchor);
          window.URL.revokeObjectURL(url);
        }, 0);
      } else {
        message.error('Ocorreu um erro ao exportar o relatório');
      }
    }).catch((err) => {
      console.log('Erro ', err);
      message.error('Ocorreu um erro, tente novamente mais tarde');
      setLoading(false);
    });
  }

  function handleDelete(id) {
    setLoading(true);
    del({ url: `/reportMonth?id=${id}` }).then((response) => {
      setLoading(false);
      if (!response.error) {

        // if ((paginationInfo.current - 1) && paginationInfo.currentTotal === 1) {
        //   fetchData(paginationInfo.current - 1);
        // } else {
          fetchData(paginationInfo.current);
        // }

        message.success('Relatório excluído com sucesso');
      } else {
        message.error('Ocorreu um erro ao excluir o relatório');
      }
    }).catch((err) => {
      console.log('Erro ', err);
      message.error('Ocorreu um erro, tente novamente mais tarde');
      setLoading(false);
    });
  }

  const columns = [
    {
      title: 'Empresa',
      dataIndex: 'company',
      key: 'company',
      render: (company) => (company.name),
    },
    {
      title: 'Grupo',
      dataIndex: 'companyGroup',
      key: 'companyGroup',
      render: (companyGroup) => (companyGroup ? companyGroup.name : ''),
    },
    {
      title: 'Data',
      dataIndex: 'month',
      key: 'month',
      render: (val, obj) => val ? `${(`0${obj?.month}`).slice(-2)}/${obj?.year}` : '',
    },
    {
      title: 'Ações',
      dataIndex: 'id',
      key: 'id',
      render: (id, obj) => {
        return (
          <div className="action-container">
            {obj.fileStatus === 'generated' && (
              <div
                className="action-item"
                onClick={() => handleDownload(obj.company.id, obj.company.name)}
              >
                <DownloadIcon />
              </div>
            )}
            {obj.fileStatus === 'processing' && (
              <Tooltip
                className="action-item-disabled"
                title="Este relatório está sendo gerado e estará disponível em breve."
              >
                <DownloadIcon />
              </Tooltip>
            )}
            {obj.fileStatus !== 'processing' && (
              refreshing.indexOf(obj.company.id) === -1 ? (isAdmin && (
                <div className="action-item" onClick={() => handleCreate(obj.company.id)}>
                  {obj.fileStatus === 'generated' ? <RefreshIcon /> : <PlusIcon />}
                </div>
              )) : (
                <Tooltip
                  className="action-item rotating action-item-disabled"
                  title="Este relatório está sendo gerado e estará disponível em breve."
                >
                  <RefreshIcon />
                </Tooltip>
              )
            )}

            { isAdmin && (
              <Popconfirm
                title="Tem certeza que deseja excluir este relatório?"
                onConfirm={() => handleDelete(id)}
                okText="Sim"
                cancelText="Não"
              >
                <div className="action-item">
                  <TrashIcon />
                </div>
              </Popconfirm>
            )}
          </div>
        );
      },
    },
  ];

  return (
    <div className="MonthReportList">
      <Spin
        tip="Carregando"
        spinning={loading}
      >

        <Form
          form={form}
          onFinish={() => fetchData(1)}
          initialValues={initialData}
          labelAlign="left"
          layout="vertical"
          noValidate // disable HTML5 validation
        >
          <Row gutter={24} className="filters-container">
            <Col xs={{ span: 24 }} lg={{ span: 6 }}>
              <Form.Item
                label="Mês"
                name="month"
                rules={[{ required: true, message: 'Por favor, selecione um mês' }]}
              >
                <Select style={{ minWidth: 80, width: '100%' }}>
                  <Select.Option key="01" value="1">Janeiro</Select.Option>
                  <Select.Option key="02" value="2">Fevereiro</Select.Option>
                  <Select.Option key="03" value="3">Março</Select.Option>
                  <Select.Option key="04" value="4">Abril</Select.Option>
                  <Select.Option key="05" value="5">Maio</Select.Option>
                  <Select.Option key="06" value="6">Junho</Select.Option>
                  <Select.Option key="07" value="7">Julho</Select.Option>
                  <Select.Option key="08" value="8">Agosto</Select.Option>
                  <Select.Option key="09" value="9">Setembro</Select.Option>
                  <Select.Option key="10" value="10">Outubro</Select.Option>
                  <Select.Option key="11" value="11">Novembro</Select.Option>
                  <Select.Option key="12" value="12">Dezembro</Select.Option>
                </Select>
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} lg={{ span: 6 }}>
              <Form.Item
                label="Ano"
                name="year"
                rules={[{ required: true, message: 'Por favor, selecione uma opção' }]}
              >
                <Select style={{ minWidth: 80, width: '100%' }}>
                  <Select.Option key="2021" value="2021">2021</Select.Option>
                  <Select.Option key="2022" value="2022">2022</Select.Option>
                  <Select.Option key="2023" value="2023">2023</Select.Option>
                  <Select.Option key="2024" value="2024">2024</Select.Option>
                  <Select.Option key="2025" value="2025">2025</Select.Option>
                  <Select.Option key="2026" value="2026">2026</Select.Option>
                  <Select.Option key="2027" value="2027">2027</Select.Option>
                  <Select.Option key="2028" value="2028">2028</Select.Option>
                  <Select.Option key="2029" value="2029">2029</Select.Option>
                  <Select.Option key="2030" value="2030">2030</Select.Option>
                </Select>
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} lg={{ span: 6 }} />
            <Col xs={{ span: 12 }} lg={{ span: 3 }}>
              {/*<Button*/}
              {/*  disable={loading}*/}
              {/*  className="SecondaryButton button-filter"*/}
              {/*  type="primary"*/}
              {/*  onClick={cleanData}*/}
              {/*>*/}
              {/*  Limpar*/}
              {/*</Button>*/}
            </Col>
            <Col xs={{ span: 12 }} lg={{ span: 3 }}>
              <Button
                disable={loading}
                className="PrimaryButton button-filter"
                type="primary"
                htmlType="submit"
                loading={loading}
              >
                Filtrar
              </Button>
            </Col>
          </Row>
        </Form>

        <Table dataSource={listData} columns={columns} pagination={false} rowKey="id" />
        {/*<Pagination*/}
        {/*  className="pagination-container"*/}
        {/*  onChange={(page) => { fetchData(page); }}*/}
        {/*  defaultCurrent={1}*/}
        {/*  current={paginationInfo.current}*/}
        {/*  pageSize={paginationInfo.pageSize}*/}
        {/*  total={paginationInfo.total}*/}
        {/*/>*/}
      </Spin>
    </div>
  );
}
