// React Imports
import React, { useState, useEffect, useCallback } from 'react';
import { useHistory, withRouter, Prompt } from 'react-router-dom';
import MaskedInput from 'react-text-mask';

// External Packages

// Redux
import { connect } from 'react-redux';

// Ant Design, Icons and Stylesheet
import {
  Form, Input, Button, Row, Col, Tooltip, Spin, Modal,
} from 'antd';
import { WarningTwoTone, CheckCircleTwoTone } from '@ant-design/icons';
import './EditMyProfile.scss';
import { changeHeaderTitle } from '../../../redux/actions/app';

// Assets, Constants and Resources
import { EditMyProfileMessages } from '../../../assets/strings/strings';
import { GetMyProfileCodes, EditMyProfileCodes } from '../../../utils/constants/response-codes';
import colors from '../../../assets/styles/colors';
import { ProfilePermissions } from '../../../utils/constants/permissions-feature';

// Services, Requests and Utilities
import MessageUtils from '../../../utils/message-utils';
import { routesPaths } from '../../../routes/RoutesPaths';
import { getMyProfile, editMyProfile } from '../../../dataSource/user_requests';
import { formatTelephone } from '../../../utils/format';
import { fieldNameRules, fieldEmailRules, fieldTelephoneRules } from '../../../utils/validations';
import { removeUserFromStorage, getUserPermissions } from '../../../utils/users';
import { useAsyncState } from '../../../utils/asyncState';

// Components

const EditMyProfile = (props) => {
  const [, updateState] = useState();
  const forceUpdate = useCallback(() => updateState({}), []);

  const history = useHistory();

  const [form] = Form.useForm();

  const [isLoadingData, setLoadingData] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [isLeavingByCancelButton, setLeavingByCancelButton] = useAsyncState(false);

  const { changeHeaderTitle } = props;

  const [initialUserData, setInitialUserData] = useState({
    name: '',
    email: '',
    emailConfirmed: '',
    telephone: '',
  });

  const canEdit = (getUserPermissions() || []).includes(ProfilePermissions.EDIT_MY_PROFILE);

  useEffect(() => {
    async function loadData() {
      setLoadingData(true);

      const response = await getMyProfile();

      const mask = (rawValue) => {
        const value = rawValue.replace(/\D/g, ''); // Only numbers
        if (value.length <= 10) {
          return `(${value.slice(0, 2)}) ${value.slice(2, 6)}-${value.slice(6, 10)}`;
        }
        return `(${value.slice(0, 2)}) ${value.slice(2, 7)}-${value.slice(7, 11)}`;
      };

      if (response.code === GetMyProfileCodes.SUCCESS) {
        setInitialUserData({
          name: response.user.name,
          email: response.user.email,
          emailConfirmed: response.user.emailConfirmed,
          telephone: mask(response.user.telephone),
        });
        form.resetFields();
      } else {
        MessageUtils.error({
          message: EditMyProfileMessages.errorLoadingUserData,
          description: EditMyProfileMessages.errorLoadingUserDataDetails,
        });
      }

      setLoadingData(false);
    }

    changeHeaderTitle(EditMyProfileMessages.pageTitle);

    loadData();

    return function cleanup() {
      changeHeaderTitle('');
    };
  }, []);

  function showPromptOnLeave() {
    let updated = false;

    const params = {
      name: form.getFieldValue('name')
        ? form.getFieldValue('name')
        : '',
      email: form.getFieldValue('email')
        ? form.getFieldValue('email')
        : '',
      telephone: form.getFieldValue('telephone')
        ? form.getFieldValue('telephone').replace(/\D/g, '')
        : '',
    };

    if (params.name !== initialUserData.name) {
      updated = true;
    }

    if (params.email !== initialUserData.email) {
      updated = true;
    }

    if (params.telephone !== initialUserData.telephone.replace(/\D/g, '')) {
      updated = true;
    }

    return updated;
  }

  function handleCancel() {
    if (showPromptOnLeave()) {
      Modal.confirm({
        title: EditMyProfileMessages.updatedDataWillBeLost,
        content: EditMyProfileMessages.areYouSureWantCancel,
        okText: EditMyProfileMessages.yesCancel,
        cancelText: EditMyProfileMessages.no,
        autoFocusButton: null,
        icon: <WarningTwoTone twoToneColor={colors.warning} />,
        onOk: async () => {
          await setLeavingByCancelButton(true);
          history.push(routesPaths.root);
        },
      });
    } else {
      history.push(routesPaths.root);
    }
  }

  async function handleSubmit() {
    setLoading(true);

    const params = {
      name: form.getFieldValue('name'),
      email: form.getFieldValue('email'),
      telephone: form.getFieldValue('telephone').replace(/\D/g, ''),
    };

    const response = await editMyProfile(params);

    if (response.code === EditMyProfileCodes.success.EMAIL_CHANGED) {
      removeUserFromStorage();
      Modal.confirm({
        cancelButtonProps: { style: { display: 'none' } },
        title: EditMyProfileMessages.dataUpdatedSuccessfully,
        content: EditMyProfileMessages.checkEmailAndLoginAgain,
        cancelType: 'cancel',
        autoFocusButton: null,
        icon: <CheckCircleTwoTone twoToneColor={colors.success} />,
      });
      history.push(routesPaths.login);
    } else if (response.code === EditMyProfileCodes.success.EMAIL_NOT_CHANGED) {
      MessageUtils.success({ message: EditMyProfileMessages.dataUpdatedSuccessfully });
      history.push(routesPaths.root);
    } else {
      switch (response.code) {
        case EditMyProfileCodes.error.EMAIL_ALREADY_IN_USE:
          MessageUtils.error({ message: EditMyProfileMessages.errorUpdatingData, description: `${EditMyProfileMessages.emailAlreadyInUse1Of2} ${params.email} ${EditMyProfileMessages.emailAlreadyInUse2Of2}` });
          document.querySelector('#email').focus();
          break;

        default:
          MessageUtils.error({ description: EditMyProfileMessages.errorUpdatingData });
          break;
      }
    }

    setLoading(false);
  }

  const onChangeEmail = (newEmail) => {
    let newEmailConfirmed;

    if (newEmail != initialUserData.email || !initialUserData.emailConfirmed) {
      newEmailConfirmed = false;
    } else {
      newEmailConfirmed = true;
    }

    if (newEmailConfirmed != form.getFieldValue('emailConfirmed')) {
      form.setFieldsValue({ emailConfirmed: newEmailConfirmed });
      forceUpdate();
    }
  };

  const checkEmailValidation = () => {
    const emailConfirmed = form.getFieldValue('emailConfirmed');

    if (!emailConfirmed) {
      return (
        <Tooltip title={EditMyProfileMessages.notConfirmedEmail}>
          <WarningTwoTone twoToneColor={colors.warning} />
        </Tooltip>
      );
    }

    return (
      <Tooltip title={EditMyProfileMessages.confirmedEmail}>
        <CheckCircleTwoTone twoToneColor={colors.information} />
      </Tooltip>
    );
  };

  const mask = (rawValue) => {
    if (rawValue.length > 14) {
      return [
        '(',
        /[1-9]/,
        /\d/,
        ')',
        ' ',
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        '-',
        /\d/,
        /\d/,
        /\d/,
        /\d/,
      ];
    }
    return [
      '(',
      /[1-9]/,
      /\d/,
      ')',
      ' ',
      /\d/,
      /\d/,
      /\d/,
      /\d/,
      '-',
      /\d/,
      /\d/,
      /\d/,
      /\d/,
    ];
  };

  return (
    <div className="EditMyProfile">
      <Prompt
        message={(location) => {
          if (location.pathname != routesPaths.editMyProfile) {
            if (showPromptOnLeave() && !isLeavingByCancelButton) {
              return `${EditMyProfileMessages.areYouSureWantCancel}\n${EditMyProfileMessages.updatedDataWillBeLost}`;
            }
          }
        }}
      />

      <Form
        form={form}
        className="EditMyProfileForm"
        onFinish={() => handleSubmit()}
        initialValues={initialUserData}
        layout="vertical"
      >
        <div className="page-content">
          <Spin
            spinning={isLoading || isLoadingData}
            tip={isLoadingData ? `${EditMyProfileMessages.loadingData}...` : `${EditMyProfileMessages.updatingData}...`}
          >
            <Form.Item
              label={EditMyProfileMessages.fieldName}
              name="name"
              rules={fieldNameRules()}
              getValueFromEvent={(evn) => evn.target.value.replace(/[^A-Za-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ' ]+$/, '')}
            >
              <Input
                className="InputField"
                readOnly={!canEdit}
                placeholder={EditMyProfileMessages.fieldName}
              />
            </Form.Item>

            <Form.Item
              label={EditMyProfileMessages.fieldEmail}
              name="email"
              rules={fieldEmailRules()}
            >
              <Input
                className="InputField"
                type="email"
                readOnly={!canEdit}
                onChange={(emailEvent) => onChangeEmail(emailEvent.target.value)}
                placeholder={EditMyProfileMessages.fieldEmail}
                suffix={checkEmailValidation()}
              />
            </Form.Item>

            <Form.Item
              label={EditMyProfileMessages.fieldTelephone}
              name="telephone"
              rules={fieldTelephoneRules()}
              getValueFromEvent={(evt) => formatTelephone(evt.target.value)}
            >
              <MaskedInput
                guide={false}
                mask={mask}
                readOnly={!canEdit}
                placeholder={EditMyProfileMessages.fieldTelephone}
                className="ant-input InputField"
                maxLength={15}
              />
            </Form.Item>
          </Spin>
        </div>
        <Row gutter={24} className="actions-container">
          <Col
            lg={{ offset: 10, span: 14 }}
            md={{ span: 24 }}
            xs={{ span: 24 }}
          >
            <Button
              className={`SecondaryButton cancel ${!canEdit ? 'cantEdit' : ''}`}
              loading={isLoading || isLoadingData}
              onClick={() => handleCancel()}
            >
              {
                canEdit
                  ? EditMyProfileMessages.cancelButton.toUpperCase()
                  : EditMyProfileMessages.backButton.toUpperCase()
              }
            </Button>

            {
              canEdit
              && (
                <Button
                  className="PrimaryButton save"
                  type="primary"
                  htmlType="submit"
                  loading={isLoading || isLoadingData}
                >
                  {EditMyProfileMessages.saveButton.toUpperCase()}
                </Button>
              )
            }
          </Col>
        </Row>
      </Form>
    </div>
  );
};

const mapStateToProps = () => ({});

const mapDispatchToProps = {
  changeHeaderTitle,
};

EditMyProfile.propTypes = {};

EditMyProfile.defaultProps = {};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EditMyProfile));
