import React, { useEffect, useState } from 'react';
import { Col, Form, message, Input, Row, Select, Spin, InputNumber, Button } from 'antd';
import { useHistory, useRouteMatch } from 'react-router-dom';
import './ExampleForm1.scss';
import { sampleGET } from '../../../dataSource/data_repository';
import { ExampleForm1Messages } from '../../../assets/strings/strings';

const { Option } = Select;

const arrayValores = [
  { id: 1, name: 'Nome 1' },
  { id: 2, name: 'Nome 2' },
  { id: 3, name: 'Nome 3' },
  { id: 4, name: 'Nome 4' },
  { id: 5, name: 'Nome 5' },
  { id: 6, name: 'Nome 6' },
  { id: 7, name: 'Nome 7' },
];

export default function ExampleForm1() {
  const history = useHistory();
  const [form] = Form.useForm();
  const [initialData, setInitialData] = useState({});
  const [loading, setLoading] = useState(false);

  const { id } = useRouteMatch().params;

  useEffect(() => {
    sampleGET({ url: `/${id}` }).then((response1) => {
      setLoading(false);
      console.log('response1 ', response1);
      const response = {
        error: false,
      };
      if (!response.error) {
        setInitialData({
          selectField: 3,
          textField: '',
          numberField: 3,
          textAreaField: '',
        });
        form.resetFields();
      } else {
        message.error(ExampleForm1Messages.messageErrorLoadingData);
      }
    }).catch((err) => {
      console.log('Erro ', err);
      message.error(ExampleForm1Messages.messageGeneralRequestError);
      setLoading(false);
    });
  }, []);

  async function handleSubmit() {
    setLoading(true);
    console.log('FieldsValue ', form.getFieldsValue());
    sampleGET({ url: '/form-url' }).then((response1) => {
      setLoading(false);
      console.log('response1 ', response1);
      const response = {
        error: false,
      };
      if (!response.error) {
        message.success(ExampleForm1Messages.messageSuccessfullySubmittedForm);
        history.push('/');
      } else {
        message.error(ExampleForm1Messages.messageErrorSendingData);
      }
    }).catch((err) => {
      console.log('Erro ', err);
      message.error(ExampleForm1Messages.messageGeneralRequestError);
      setLoading(false);
    });
  }

  return (
    <div className="ExampleForm1">
      <Spin
        tip={ExampleForm1Messages.spinLoading}
        spinning={loading}
      >
        <Form
          form={form}
          onFinish={handleSubmit}
          initialValues={initialData}
          labelAlign="left"
          layout="vertical"
          noValidate // disable HTML5 validation
        >
          <Row gutter={24}>
            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
              <Form.Item
                label="Select"
                name="selectField"
                rules={[{ required: true, message: ExampleForm1Messages.validationPleaseSelectAnOption }]}
              >
                <Select style={{ minWidth: 80, width: '100%' }}>
                  {
                    arrayValores.map(v => (
                      <Option key={v.id} value={v.id}>
                        {v.name}
                      </Option>
                    ))
                  }
                </Select>
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
              <Form.Item
                label="Text"
                name="textField"
                rules={[
                  { required: true, message: ExampleForm1Messages.validationPleaseFillInTheField },
                  { max: 200, message: ExampleForm1Messages.validationCharactersMaximum200 },
                ]}
              >
                <Input placeholder="Campo de texto" />
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
              <Form.Item
                label="Number"
                name="numberField"
                rules={[
                  { required: true, message: ExampleForm1Messages.validationPleaseFillInTheField },
                ]}
              >
                <InputNumber style={{ width: '100%' }} min={0} max={30} />
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
              <Form.Item
                label="Text Area"
                name="textAreaField"
                rules={[{ required: true, message: ExampleForm1Messages.validationPleaseFillInTheField }]}
              >
                <Input.TextArea placeholder="Campo TextArea" rows={4} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24} className="actions-container">
            <Col
              lg={{ offset: 10, span: 14 }}
              md={{ span: 24 }}
              xs={{ span: 24 }}
            >
              <Button
                className="SecondaryButton cancel"
                disable={loading}
                onClick={() => { history.push('/'); }}
              >
                {ExampleForm1Messages.buttomCancel.toUpperCase()}
              </Button>

              <Button
                disable={loading}
                className="PrimaryButton save"
                type="primary"
                htmlType="submit"
                loading={loading}
              >
                {ExampleForm1Messages.buttomSave.toUpperCase()}
              </Button>
            </Col>
          </Row>
        </Form>
      </Spin>
    </div>
  );
}
