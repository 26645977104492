import React, { useEffect, useState } from 'react';
import { Col, DatePicker, Form, message, Row, Spin, Button } from 'antd';
import { useHistory, useRouteMatch } from 'react-router-dom';
import moment from 'moment/moment';
import './ExampleForm3.scss';
import { sampleGET } from '../../../dataSource/data_repository';
import { ExampleForm3Messages } from '../../../assets/strings/strings';

const { RangePicker } = DatePicker;

export default function ExampleForm3() {
  const history = useHistory();
  const [form] = Form.useForm();
  const [initialData, setInitialData] = useState({});
  const [loading, setLoading] = useState(false);

  const { id } = useRouteMatch().params;

  useEffect(() => {
    sampleGET({ url: `/${id}` }).then((response1) => {
      setLoading(false);
      console.log('response1 ', response1);
      const response = {
        error: false,
      };
      if (!response.error) {
        setInitialData({
          datePickerField: moment(),
          rangePickerField: [moment().subtract(30, 'day'), moment()],
        });
        form.resetFields();
      } else {
        message.error(ExampleForm3Messages.messageErrorLoadingData);
      }
    }).catch((err) => {
      console.log('Erro ', err);
      message.error(ExampleForm3Messages.messageGeneralRequestError);
      setLoading(false);
    });
  }, []);

  async function handleSubmit() {
    setLoading(true);
    console.log('FieldsValue ', form.getFieldsValue());
    sampleGET({ url: '/form-url' }).then((response1) => {
      setLoading(false);
      console.log('response1 ', response1);
      const response = {
        error: false,
      };
      if (!response.error) {
        message.success(ExampleForm3Messages.messageSuccessfullySubmittedForm);
        history.push('/');
      } else {
        message.error(ExampleForm3Messages.messageErrorSendingData);
      }
    }).catch((err) => {
      console.log('Erro ', err);
      message.error(ExampleForm3Messages.messageGeneralRequestError);
      setLoading(false);
    });
  }

  return (
    <div className="ExampleForm3">
      <Spin
        tip={ExampleForm3Messages.spinLoading}
        spinning={loading}
      >
        <Form
          form={form}
          onFinish={handleSubmit}
          initialValues={initialData}
          labelAlign="left"
          layout="vertical"
          noValidate // disable HTML5 validation
        >
          <Row gutter={24}>
            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
              <Form.Item
                label="Date Picker"
                name="datePickerField"
                rules={[{ required: true, message: ExampleForm3Messages.validationPleaseSelectADate }]}
              >
                <DatePicker format="DD/MM/YYYY" style={{ width: '100%' }} />
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
              <Form.Item
                label="Range Picker"
                name="rangePickerField"
                rules={[
                  { required: true, message: ExampleForm3Messages.validationPleaseSelectARange },
                ]}
              >
                <RangePicker format="DD/MM/YYYY" style={{ width: '100%' }} />
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
              <Form.Item
                label="Date Picker Month"
                name="datePickerMonthField"
                rules={[{ required: true, message: ExampleForm3Messages.validationPleaseSelectADate }]}
              >
                <DatePicker picker="month" format="MM/YYYY" style={{ width: '100%' }} />
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
              <Form.Item
                label="Date Picker year"
                name="rangePickeryearField"
                rules={[
                  { required: true, message: ExampleForm3Messages.validationPleaseSelectARange },
                ]}
              >
                <DatePicker picker="year" style={{ width: '100%' }} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24} className="actions-container">
            <Col
              lg={{ offset: 10, span: 14 }}
              md={{ span: 24 }}
              xs={{ span: 24 }}
            >
              <Button
                className="SecondaryButton cancel"
                disable={loading}
                onClick={() => { history.push('/'); }}
              >
                {ExampleForm3Messages.buttomCancel.toUpperCase()}
              </Button>

              <Button
                disable={loading}
                className="PrimaryButton save"
                type="primary"
                htmlType="submit"
                loading={loading}
              >
                {ExampleForm3Messages.buttomSave.toUpperCase()}
              </Button>
            </Col>
          </Row>
        </Form>
      </Spin>
    </div>
  );
}
