// React Imports
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

// External Packages

// Ant Design, Icons and Stylesheet
import { Button, Form, Input } from 'antd';
import './ForgotPassword.scss';

// Assets, Constants and Resources
import { ForgotPasswordMessages, LoginPageMessages } from '../../assets/strings/strings';
import { ForgotPasswordCodes } from '../../utils/constants/response-codes';

// Services, Requests and Utilities
import MessageUtils from '../../utils/message-utils';
import { forgotPassword } from '../../dataSource/auth_requests';
import { routesPaths } from '../../routes/RoutesPaths';

// Components
import BaseFormComponent from '../../components/base_form_component/BaseFormComponent';
import HyperlinkButton from '../../components/hyperlinkButton/HyperlinkButton';

const ForgotPasswordPage = () => {
  const history = useHistory();

  const [isLoading, setLoading] = useState(false);

  const [form] = Form.useForm();

  async function handleSubmit() {
    const email = form.getFieldValue('email');

    setLoading(true);

    const response = await forgotPassword({ email: email.toLowerCase() });

    setLoading(false);

    switch (response.code) {
      case ForgotPasswordCodes.SUCCESS:
        MessageUtils.success({
          message: ForgotPasswordMessages.verifyYourEmail,
          description: ForgotPasswordMessages.instructionsSent,
        });
        break;

      case ForgotPasswordCodes.error.USER_NOT_FOUND:
        MessageUtils.error({
          message: ForgotPasswordMessages.userNotFound,
          description: ForgotPasswordMessages.verifyAndTryAgain,
        });
        break;

      default:
        MessageUtils.error({
          description: ForgotPasswordMessages.verifyAndTryAgain,
        });
        break;
    }
  }

  return (
    <div className="ForgotPassword">
      <BaseFormComponent>
        <div>
          <h3 className="formTitle">
            {ForgotPasswordMessages.forgotPassword}
          </h3>
          <p className="formText">
            {ForgotPasswordMessages.insertYourEmail}
          </p>
          <Form
            className="form"
            form={form}
            onFinish={() => handleSubmit()}
            noValidate
          >
            <Form.Item
              name="email"
              rules={
                [
                  {
                    required: true,
                    message: LoginPageMessages.insertYourEmail,
                  },
                  {
                    type: 'email',
                    message: LoginPageMessages.invalidEmailFormat,
                  },
                  {
                    pattern: /^[a-z,A-z,0-9,@,.,_,-]+$/,
                    message: LoginPageMessages.dontUseSpecialCharacters,
                  },
                ]
              }
            >
              <Input
                className="InputField"
                type="email"
                placeholder={LoginPageMessages.emailPlaceholder}
              />
            </Form.Item>

            <Form.Item
              name="submit"
            >
              <Button
                type="primary"
                htmlType="submit"
                className="PrimaryButton"
                loading={isLoading}
              >
                {ForgotPasswordMessages.confirm}
              </Button>
            </Form.Item>

            <HyperlinkButton onClick={() => history.push(routesPaths.root)}>
              {ForgotPasswordMessages.backToLogin}
            </HyperlinkButton>
          </Form>
        </div>
      </BaseFormComponent>
    </div>
  );
};

ForgotPasswordPage.propTypes = {};

ForgotPasswordPage.defaultProps = {};

export default ForgotPasswordPage;
