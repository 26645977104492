import React, { useEffect, useState } from 'react';
import { Col, Form, message, Input, Row, Spin, Button } from 'antd';
import { useHistory, useRouteMatch } from 'react-router-dom';
import './ExampleForm2.scss';
import { sampleGET } from '../../../dataSource/data_repository';
import {ExampleForm1Messages, ExampleForm2Messages} from '../../../assets/strings/strings';
import { formatCPF, formatCNPJ, formatTelephone } from '../../../utils/format';
import { validateCPF, validateCNPJ } from '../../../utils/validations';

export default function ExampleForm2() {
  const history = useHistory();
  const [form] = Form.useForm();
  const [initialData, setInitialData] = useState({});
  const [loading, setLoading] = useState(false);

  const { id } = useRouteMatch().params;

  useEffect(() => {
    sampleGET({ url: `/${id}` }).then((response1) => {
      setLoading(false);
      console.log('response1 ', response1);
      const response = {
        error: false,
      };
      if (!response.error) {
        setInitialData({
          selectField: 3,
          textField: '',
          numberField: 3,
          textAreaField: '',
        });
        form.resetFields();
      } else {
        message.error(ExampleForm2Messages.messageErrorLoadingData);
      }
    }).catch((err) => {
      console.log('Erro ', err);
      message.error(ExampleForm2Messages.messageGeneralRequestError);
      setLoading(false);
    });
  }, []);

  async function handleSubmit() {
    setLoading(true);
    console.log('FieldsValue ', form.getFieldsValue());
    sampleGET({ url: '/form-url' }).then((response1) => {
      setLoading(false);
      console.log('response1 ', response1);
      const response = {
        error: false,
      };
      if (!response.error) {
        message.success(ExampleForm2Messages.messageSuccessfullySubmittedForm);
        history.push('/');
      } else {
        message.error(ExampleForm2Messages.messageErrorSendingData);
      }
    }).catch((err) => {
      console.log('Erro ', err);
      message.error(ExampleForm2Messages.messageGeneralRequestError);
      setLoading(false);
    });
  }

  return (
    <div className="ExampleForm2">
      <Spin
        tip={ExampleForm2Messages.spinLoading}
        spinning={loading}
      >
        <Form
          form={form}
          onFinish={handleSubmit}
          initialValues={initialData}
          labelAlign="left"
          layout="vertical"
          noValidate // disable HTML5 validation
        >
          <Row gutter={24}>
            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
              <Form.Item
                label={ExampleForm2Messages.nameField}
                name="nameField"
                rules={[
                  { required: true, message: ExampleForm2Messages.validationRequired },
                  { min: 5, message: ExampleForm1Messages.validationCharactersMinimum200 },
                  { max: 200, message: ExampleForm1Messages.validationCharactersMaximum200 },
                ]}
              >
                <Input placeholder="" />
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
              <Form.Item
                label={ExampleForm2Messages.telephoneField}
                name="telephoneField"
                rules={[
                  { required: true, message: ExampleForm2Messages.validationRequired },
                ]}
                getValueFromEvent={event => formatTelephone(event.target.value)}
              >
                <Input placeholder="" />
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
              <Form.Item
                label={ExampleForm2Messages.cnpjField}
                name="cnpjField"
                rules={[
                  { required: true, message: ExampleForm2Messages.validationRequired },
                  () => ({
                    validator(rule, value) {
                      if (!value || validateCNPJ(value)) {
                        return Promise.resolve();
                      }
                      return Promise.reject(ExampleForm2Messages.validationCNPJ);
                    },
                  }),
                ]}
                getValueFromEvent={event => formatCNPJ(event.target.value)}
              >
                <Input placeholder="" />
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
              <Form.Item
                label={ExampleForm2Messages.cpfField}
                name="cpfField"
                rules={[
                  { required: true, message: ExampleForm2Messages.validationRequired },
                  () => ({
                    validator(rule, value) {
                      if (!value || validateCPF(value)) {
                        return Promise.resolve();
                      }
                      return Promise.reject(ExampleForm2Messages.validationCPF);
                    },
                  }),
                ]}
                getValueFromEvent={event => formatCPF(event.target.value)}
              >
                <Input placeholder="" />
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} lg={{ span: 24 }}>
              <Form.Item
                name="emailField"
                label={ExampleForm2Messages.emailField}
                rules={[
                  { type: 'email', message: ExampleForm2Messages.validationEmail },
                  { required: true, message: ExampleForm2Messages.validationRequired },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
              <Form.Item
                name="passwordField"
                label={ExampleForm2Messages.passwordField}
                rules={[
                  { required: true, message: ExampleForm2Messages.validationRequired },
                ]}
              >
                <Input.Password />
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
              <Form.Item
                name="confirmPasswordField"
                label={ExampleForm2Messages.confirmPasswordField}
                dependencies={['passwordField']}
                rules={[
                { required: true, message: ExampleForm2Messages.validationRequired },
                ({ getFieldValue }) => ({
                  validator(rule, value) {
                    if (!value || getFieldValue('passwordField') === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(ExampleForm2Messages.validationConfirmPassword);
                  },
                }),
              ]}
                >
                <Input.Password />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24} className="actions-container">
            <Col
              lg={{ offset: 10, span: 14 }}
              md={{ span: 24 }}
              xs={{ span: 24 }}
            >
              <Button
                className="SecondaryButton cancel"
                disable={loading}
                onClick={() => { history.push('/'); }}
              >
                {ExampleForm2Messages.buttomCancel.toUpperCase()}
              </Button>

              <Button
                disable={loading}
                className="PrimaryButton save"
                type="primary"
                htmlType="submit"
                loading={loading}
              >
                {ExampleForm2Messages.buttomSave.toUpperCase()}
              </Button>
            </Col>
          </Row>
        </Form>
      </Spin>
    </div>
  );
}
