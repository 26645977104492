import React from 'react';
import FileDocumentIcon from 'mdi-react/FileDocumentIcon';
import HomeIcon from 'mdi-react/HomeIcon';
import PlusIcon from 'mdi-react/PlusIcon';
import UserIcon from 'mdi-react/UserIcon';
import ProgressClockIcon from 'mdi-react/ProgressClockIcon';
import UploadIcon from 'mdi-react/UploadIcon';

import { BASIC_PERMISSION, ProfilePermissions } from '../../utils/constants/permissions-feature';
import { routesPaths } from '../../routes/RoutesPaths';
import { BarAndHeaderMessages } from '../../assets/strings/strings';

const menuItens = [
  {
    name: 'Relatórios',
    permissions: [BASIC_PERMISSION],
    icon: <FileDocumentIcon />,
    subMenu: [],
    url: '/relatorios',
    headerTitle: 'Relatórios',
    documentTitle: 'Relatórios',
    standalone: true,
  },
  {
    name: 'Relatório Mensal',
    permissions: [BASIC_PERMISSION],
    icon: <FileDocumentIcon />,
    subMenu: [
      {
        name: 'WhatsApp',
        url: '/relatorio-mensal/whatsapp',
        headerTitle: 'Relatório Mensal WhatsApp',
        documentTitle: 'Relatório Mensal WhatsApp',
        permissions: [BASIC_PERMISSION],
      },
      {
        name: 'SMS',
        url: '/relatorio-mensal/sms',
        headerTitle: 'Relatório Mensal SMS',
        documentTitle: 'Relatório Mensal SMS',
        permissions: [BASIC_PERMISSION],
      },
      {
        name: 'E-mail',
        url: '/relatorio-mensal/email',
        headerTitle: 'Relatório Mensal E-mail',
        documentTitle: 'Relatório Mensal E-mail',
        permissions: [BASIC_PERMISSION],
      },
      {
        name: 'Total',
        url: '/relatorio-total',
        headerTitle: 'Total de disparos',
        documentTitle: 'Total de disparos',
        permissions: [BASIC_PERMISSION],
      },
    ],
  },
  {
    name: 'Importar',
    icon: <UploadIcon />,
    permissions: ['EDIT_SYSTEM_PARAMETER'],
    subMenu: [
      {
        name: 'WhatsApp',
        url: '/importar/whatsapp',
        headerTitle: 'Importação planilha de WhatsApp',
        documentTitle: 'Importação planilha de WhatsApp',
        permissions: ['EDIT_SYSTEM_PARAMETER'],
      },
      {
        name: 'SMS',
        url: '/importar/sms',
        headerTitle: 'Importação planilha de SMS',
        documentTitle: 'Importação planilha de SMS',
        permissions: ['EDIT_SYSTEM_PARAMETER'],
      },
      {
        name: 'E-mail',
        url: '/importar/email',
        headerTitle: 'Importação planilha de E-mail',
        documentTitle: 'Importação planilha de E-mail',
        permissions: ['EDIT_SYSTEM_PARAMETER'],
      },
      {
        name: 'Safra',
        url: '/importar/safra',
        headerTitle: 'Importação planilha Safra',
        documentTitle: 'Importação planilha Safra',
        permissions: ['EDIT_SYSTEM_PARAMETER'],
      },
    ],
  },
  {
    name: 'Status',
    icon: <ProgressClockIcon />,
    permissions: ['VIEW_MARK_UP_SOLICITATIONS'],
    subMenu: [
      {
        name: 'WhatsApp',
        url: '/status/whatsapp',
        headerTitle: 'Status WhatsApp',
        documentTitle: 'Status WhatsApp',
        permissions: ['VIEW_MARK_UP_SOLICITATIONS'],
      },
      {
        name: 'SMS',
        url: '/status/sms',
        headerTitle: 'Status SMS',
        documentTitle: 'Status SMS',
        permissions: ['VIEW_MARK_UP_SOLICITATIONS'],
      },
      {
        name: 'E-mail',
        url: '/status/email',
        headerTitle: 'Status E-mail',
        documentTitle: 'Status E-mail',
        permissions: ['VIEW_MARK_UP_SOLICITATIONS'],
      },
      {
        name: 'Sincronização',
        url: '/status/sync',
        headerTitle: 'Sincronização manual',
        documentTitle: 'Sincronização manual',
        permissions: ['EDIT_SYSTEM_PARAMETER'],
      },
    ],
  },
  {
    name: 'Cadastro',
    icon: <PlusIcon />,
    permissions: ['EDIT_SYSTEM_PARAMETER'],
    subMenu: [
      {
        name: 'Grupos',
        url: '/grupos/listar',
        headerTitle: 'Lista de grupos de empresas',
        documentTitle: 'Lista de grupos de empresas',
        permissions: ['EDIT_SYSTEM_PARAMETER'],
      },
      {
        name: 'Empresas',
        url: '/empresas/listar',
        headerTitle: 'Lista de empresas',
        documentTitle: 'Lista de empresas',
        permissions: ['EDIT_SYSTEM_PARAMETER'],
      },
      {
        name: 'Usuários',
        url: '/usuarios/listar',
        headerTitle: 'Lista de usuários',
        documentTitle: 'Lista de usuários',
        permissions: ['EDIT_SYSTEM_PARAMETER'],
      },
    ],
  },
  // {
  //   name: 'Exemplos',
  //   icon: <FileDocumentIcon />,
  //   permissions: [BASIC_PERMISSION],
  //   subMenu: [
  //     {
  //       name: 'Simples',
  //       url: '/example-page',
  //       headerTitle: 'Página simples',
  //       documentTitle: 'Página simples',
  //       permissions: [BASIC_PERMISSION],
  //     },
  //     {
  //       name: 'Formulário 1',
  //       url: '/example-form1/1',
  //       headerTitle: 'Exemplo formulário 1',
  //       documentTitle: 'Exemplo formulário 1',
  //       permissions: [BASIC_PERMISSION],
  //     },
  //     {
  //       name: 'Formulário 2',
  //       url: '/example-form2/1',
  //       headerTitle: 'Exemplo formulário 2',
  //       documentTitle: 'Exemplo formulário 2',
  //       permissions: [BASIC_PERMISSION],
  //     },
  //     {
  //       name: 'Formulário 3',
  //       url: '/example-form3/1',
  //       headerTitle: 'Exemplo formulário 3',
  //       documentTitle: 'Exemplo formulário 3',
  //       permissions: [BASIC_PERMISSION],
  //     },
  //     {
  //       name: 'Formulário 4',
  //       url: '/example-form4/1',
  //       headerTitle: 'Exemplo formulário 4',
  //       documentTitle: 'Exemplo formulário 4',
  //       permissions: [BASIC_PERMISSION],
  //     },
  //     {
  //       name: 'Lista simples',
  //       url: '/example-list/',
  //       headerTitle: 'Exemplo listagem simples',
  //       documentTitle: 'Exemplo listagem simples',
  //       permissions: [BASIC_PERMISSION],
  //     },
  //     {
  //       name: 'Lista com paginação',
  //       url: '/example-pagination-list/',
  //       headerTitle: 'Exemplo listagem com paginação',
  //       documentTitle: 'Exemplo listagem com paginação',
  //       permissions: [BASIC_PERMISSION],
  //     },
  //     {
  //       name: 'Lista com filtros',
  //       url: '/example-filter-list/',
  //       headerTitle: 'Exemplo listagem com filtros',
  //       documentTitle: 'Exemplo listagem com filtros',
  //       permissions: [BASIC_PERMISSION],
  //     },
  //   ],
  // },


  // {
  //   name: BarAndHeaderMessages.profile,
  //   icon: <UserIcon />,
  //   permissions: [BASIC_PERMISSION],
  //   subMenu: [
  //     {
  //       name: BarAndHeaderMessages.myProfile,
  //       url: routesPaths.editMyProfile,
  //       headerTitle: BarAndHeaderMessages.myProfile,
  //       documentTitle: BarAndHeaderMessages.myProfile,
  //       permissions: [ProfilePermissions.VIEW_MY_PROFILE],
  //     },
  //     {
  //       name: BarAndHeaderMessages.changePassword,
  //       url: routesPaths.changePassword,
  //       headerTitle: BarAndHeaderMessages.changePassword,
  //       documentTitle: BarAndHeaderMessages.changePassword,
  //       permissions: [ProfilePermissions.CHANGE_PASSWORD],
  //     },
  //   ],
  // },
];

export {
  menuItens,
};
