import React, { useEffect, useState } from 'react';
import { message, Spin, Pagination, Table } from 'antd';
import './ExamplePaginationList.scss';
import { sampleGET } from '../../../dataSource/data_repository';
import { ExamplePaginationListMessages} from '../../../assets/strings/strings';

const dataSource = [
  {
    key: '1',
    name: 'Mike',
    age: 32,
    address: '10 Downing Street',
  },
  {
    key: '2',
    name: 'John',
    age: 42,
    address: '10 Downing Street',
  },
  {
    key: '3',
    name: 'Jim',
    age: 23,
    address: '10 Downing Street',
  },
  {
    key: '4',
    name: 'Joe',
    age: 54,
    address: '10 Downing Street',
  },
  {
    key: '5',
    name: 'Edward',
    age: 15,
    address: '10 Downing Street',
  },
  {
    key: '6',
    name: 'Elijah',
    age: 17,
    address: '10 Downing Street',
  },
  {
    key: '7',
    name: 'Lillian',
    age: 43,
    address: '10 Downing Street',
  },
  {
    key: '8',
    name: 'Jacobs',
    age: 78,
    address: '10 Downing Street',
  },
  {
    key: '9',
    name: 'Leo',
    age: 34,
    address: '10 Downing Street',
  },
  {
    key: '10',
    name: 'Maria',
    age: 25,
    address: '10 Downing Street',
  },
  {
    key: '11',
    name: 'Mason',
    age: 67,
    address: '10 Downing Street',
  },
  {
    key: '12',
    name: 'Taylor',
    age: 52,
    address: '10 Downing Street',
  },
];

const columns = [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'Age',
    dataIndex: 'age',
    key: 'age',
  },
  {
    title: 'Address',
    dataIndex: 'address',
    key: 'address',
  },
];

export default function ExamplePaginationList() {
  const [loadingData, setLoadingData] = useState(true);
  const [listData, setListData] = useState([]);
  const [paginationInfo, setPaginationInfo] = useState({
    current: 1,
    pageSize: 1,
    total: 0,
  });

  async function fetchData(page) {
    sampleGET({ url: `/?page=${page - 1}` }).then((response1) => {
      setLoadingData(false);
      console.log('response1 ', response1);
      const response = {
        error: false,
      };
      if (!response.error) {
        setListData(dataSource.slice((page - 1) * 5, page * 5));
        setPaginationInfo({
          current: page,
          pageSize: 5,
          total: dataSource.length,
        })
      } else {
        message.error(ExamplePaginationListMessages.messageErrorLoadingData);
      }
      setLoadingData(false);
    }).catch((err) => {
      console.log('Erro ', err);
      message.error(ExamplePaginationListMessages.messageGeneralRequestError);
      setLoadingData(false);
    });
  }

  useEffect(() => {
    if (loadingData) {
      fetchData(1);
    }
  }, []);

  return (
    <div className="ExamplePaginationList">
      <Spin
        tip={ExamplePaginationListMessages.spinLoading}
        spinning={loadingData}
      >
        <Table dataSource={listData} columns={columns} pagination={false} />
        <Pagination
          className="pagination-container"
          onChange={function (page) { fetchData(page) }}
          defaultCurrent={1}
          current={paginationInfo.current}
          pageSize={paginationInfo.pageSize}
          total={paginationInfo.total}
        />
      </Spin>
    </div>
  );
}
