import React from 'react';
import {
  BrowserRouter as Router,
  Route,
  HashRouter,
  Switch,
} from 'react-router-dom';

import { ProfilePermissions, BASIC_PERMISSION } from '../utils/constants/permissions-feature';

import AuthRoute from './AuthRoute';
import { routesPaths } from './RoutesPaths';

import AccessWrapper from '../pages/accessPages/accessWrapper/AccessWrapper';
import ForgotPasswordPage from '../pages/forgotPassword/ForgotPassword';

import EmailConfirmationComponent from '../pages/emailConfirmation/EmailConfirmation';
import ResetPasswordPage from '../pages/resetPassword/ResetPassword';

import InitialPage from '../pages/initial/InitialPage';
import Home from '../pages/home/Home';
import ChangePassword from '../pages/changePassword/ChangePassword';
import EditMyProfile from '../pages/profile/editMyProfile/EditMyProfile';

import NotFound from '../pages/notFound/NotFound';

import ImportFormWhatsapp from '../pages/import/ImportFormWhatsapp';
import ImportFormSMS from '../pages/import/ImportFormSMS';
import ImportFormEmail from '../pages/import/ImportFormEmail';
import ImportFormSafra from '../pages/import/ImportFormSafra';
import ReportList from '../pages/report/ReportList';
import MonthReportList from '../pages/report/monthReportList/MonthReportList';
import MonthTotalList from '../pages/report/monthTotalList/MonthTotalList';
import ReportDetails from '../pages/report/reportDetails/ReportDetails';
import ReportChartDetails from '../pages/report/reportChartDetails/ReportChartDetails';
import SyncGoogleSheet from '../pages/report/syncGoogleSheet/SyncGoogleSheet';
import ReportGoogleSheet from '../pages/report/reportGoogleSheet/ReportGoogleSheet';
import CompanyList from '../pages/company/CompanyList';
import CompanyRegister from '../pages/company/CompanyRegister';
import CompanyGroupList from '../pages/companyGroup/CompanyGroupList';
import CompanyGroupRegister from '../pages/companyGroup/CompanyGroupRegister';
import UserList from '../pages/user/UserList';
import UserRegister from '../pages/user/UserRegister';
import ExamplePage from '../pages/examples/examplePage/ExamplePage';
import ExampleForm1 from '../pages/examples/exampleForm1/ExampleForm1';
import ExampleForm2 from '../pages/examples/exampleForm2/ExampleForm2';
import ExampleForm3 from '../pages/examples/exampleForm3/ExampleForm3';
import ExampleForm4 from '../pages/examples/exampleForm4/ExampleForm4';
import ExampleList from '../pages/examples/exampleList/ExampleList';
import ExamplePaginationList from '../pages/examples/examplePaginationList/ExamplePaginationList';
import ExampleFilterList from '../pages/examples/exampleFilterList/ExampleFilterList';

import '../assets/styles/styles.scss';
import EditFormWhatsapp from '../pages/edit/EditFormWhatsapp';

export default function Routes() {
  return (
    <Router>
      <Switch>

        <AuthRoute exact path={routesPaths.root} component={InitialPage} permissions={[BASIC_PERMISSION]} />
        <Route exact path={routesPaths.login} component={AccessWrapper} />
        <Route path={routesPaths.forgotPassword} component={ForgotPasswordPage} />
        <Route path={`${routesPaths.emailConfirmation}/:token`} component={EmailConfirmationComponent} />
        <Route path={`${routesPaths.resetPassword}/:token`} component={ResetPasswordPage} />
        <AuthRoute path={routesPaths.changePassword} component={ChangePassword} permissions={[ProfilePermissions.CHANGE_PASSWORD]} />
        <AuthRoute path={routesPaths.editMyProfile} component={EditMyProfile} permissions={[ProfilePermissions.VIEW_MY_PROFILE]} />

        <AuthRoute exact path="/relatorios" component={ReportList} permissions={[BASIC_PERMISSION]} />
        <AuthRoute exact path="/relatorio-mensal/:type" component={MonthReportList} permissions={[BASIC_PERMISSION]} />
        <AuthRoute exact path="/relatorio/:id" component={ReportDetails} permissions={[BASIC_PERMISSION]} />
        <AuthRoute exact path="/graficos/:id" component={ReportChartDetails} permissions={[BASIC_PERMISSION]} />
        <AuthRoute exact path="/relatorio-total" component={MonthTotalList} permissions={[BASIC_PERMISSION]} />

        <AuthRoute exact path="/status/sync" component={SyncGoogleSheet} permissions={['EDIT_SYSTEM_PARAMETER']} />
        <AuthRoute exact path="/status/:type" component={ReportGoogleSheet} permissions={[BASIC_PERMISSION]} />

        <AuthRoute exact path="/editar/:id" component={EditFormWhatsapp} permissions={['EDIT_SYSTEM_PARAMETER']} />

        <AuthRoute exact path="/empresas/listar" component={CompanyList} permissions={['EDIT_SYSTEM_PARAMETER']} />
        <AuthRoute exact path="/empresas/cadastrar" component={CompanyRegister} permissions={['EDIT_SYSTEM_PARAMETER']} />

        <AuthRoute exact path="/grupos/listar" component={CompanyGroupList} permissions={['EDIT_SYSTEM_PARAMETER']} />
        <AuthRoute exact path="/grupos/cadastrar" component={CompanyGroupRegister} permissions={['EDIT_SYSTEM_PARAMETER']} />

        <AuthRoute exact path="/usuarios/listar" component={UserList} permissions={['EDIT_SYSTEM_PARAMETER']} />
        <AuthRoute exact path="/usuarios/cadastrar" component={UserRegister} permissions={['EDIT_SYSTEM_PARAMETER']} />
        <AuthRoute exact path="/usuarios/editar/:id" component={UserRegister} permissions={['EDIT_SYSTEM_PARAMETER']} />

        <AuthRoute path="/importar/whatsapp" component={ImportFormWhatsapp} permissions={['EDIT_SYSTEM_PARAMETER']} />
        <AuthRoute path="/importar/sms" component={ImportFormSMS} permissions={['EDIT_SYSTEM_PARAMETER']} />
        <AuthRoute path="/importar/email" component={ImportFormEmail} permissions={['EDIT_SYSTEM_PARAMETER']} />
        <AuthRoute path="/importar/safra" component={ImportFormSafra} permissions={['EDIT_SYSTEM_PARAMETER']} />

        <AuthRoute path="/example-page/" component={ExamplePage} permissions={[BASIC_PERMISSION]} />
        <AuthRoute path="/example-form1/:id" component={ExampleForm1} permissions={[BASIC_PERMISSION]} />
        <AuthRoute path="/example-form2/:id" component={ExampleForm2} permissions={[BASIC_PERMISSION]} />
        <AuthRoute path="/example-form3/:id" component={ExampleForm3} permissions={[BASIC_PERMISSION]} />
        <AuthRoute path="/example-form4/:id" component={ExampleForm4} permissions={[BASIC_PERMISSION]} />
        <AuthRoute path="/example-list/" component={ExampleList} permissions={[BASIC_PERMISSION]} />
        <AuthRoute path="/example-pagination-list/" component={ExamplePaginationList} permissions={[BASIC_PERMISSION]} />
        <AuthRoute path="/example-filter-list/" component={ExampleFilterList} permissions={[BASIC_PERMISSION]} />
        <Route component={NotFound} />

      </Switch>
    </Router>
  );
}
