import AES from 'crypto-js/aes';
import enc from 'crypto-js/enc-utf8';
import base64arraybuffer from 'base64-arraybuffer';
import { message } from 'antd';

import { ENCRYPTION_KEY } from '../utils/constants/constants';
import { downloadTermsAndConditionsFile } from '../dataSource/file_requests';

const encrypty = (value) => AES.encrypt(value, ENCRYPTION_KEY).toString();

const decrypty = (value) => {
  if (value) {
    return AES.decrypt(value, ENCRYPTION_KEY).toString(enc);
  }

  return null;
};

const uploadImageAndGetLink = async (fileList) => {
  let imgUrl;

  if (fileList[0].url && fileList[0].url !== '') {
    imgUrl = fileList[0].url;
  } else {
    imgUrl = await uploadImage(fileList[0]);
  }

  return imgUrl;
};

const convertBase64ToArrayBuffer = (image) => {
  const type = image[0].type.replace('image/', '');
  const imageURL = image[0].thumbUrl
    .replace(`data:image/${type};base64,`, '')
    .replace('data:image/jpg;base64,', '')
    .replace('data:image/jpeg;base64,', '')
    .replace('data:image/png;base64,', '');
  const arrayBuffer = base64arraybuffer.decode(imageURL);
  const int8array = new Int8Array(arrayBuffer);

  return Object.values(int8array);
};

const objectIsEmpty = (object) => {
  if (Object.keys(object).length) {
    return false;
  }

  return true;
};

const isEmpty = (value) => (value === null || value === undefined || value === '');

const downloadArchive = (buffer, type, fileName) => {
  const blob = new Blob([buffer], { type });
  const link = document.createElement('a');

  link.href = window.URL.createObjectURL(blob);
  link.style = 'visibility:hidden';
  link.download = fileName;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

const downloadPDF = (file) => {
  if (file.id) {
    downloadTermsAndConditionsFile()
      .then((response) => {
        downloadArchive(response, 'application/pdf', file.name);
      })
      .catch(() => {
        message.error('Erro ao fazer download do arquivo');
      });
  } else {
    downloadArchive(file.originFileObj, 'application/pdf', file.name);
  }
};

const downloadFile = (buffer, fileName) => {
  const blob = new Blob([buffer]);
  const link = document.createElement('a');

  link.href = window.URL.createObjectURL(blob);
  link.style = 'visibility:hidden';
  link.download = fileName;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export {
  encrypty,
  decrypty,
  uploadImageAndGetLink,
  convertBase64ToArrayBuffer,
  objectIsEmpty,
  isEmpty,
  downloadArchive,
  downloadPDF,
  downloadFile,
};
