import React, { useEffect, useState } from 'react';
import {
  Button, message, Spin, Pagination, Table,
} from 'antd';
import { useHistory } from 'react-router-dom';
import './CompanyList.scss';
import { get } from '../../dataSource/data_repository';

export default function CompanyList() {
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [listData, setListData] = useState([]);
  const [paginationInfo, setPaginationInfo] = useState({
    current: 1,
    currentTotal: 0,
    pageSize: 1,
    total: 0,
  });

  async function fetchData(page) {
    get({ url: `/company?page=${page - 1}` }).then((response) => {
      setLoading(false);
      if (!response.error) {
        const data = response.companies;
        setListData(data);
        // setPaginationInfo({
        //   current: page,
        //   currentTotal: data.length,
        //   pageSize: 5,
        //   total: dataSource.length,
        // });
      } else {
        message.error('Erro ao carregar os dados');
      }
      setLoading(false);
    }).catch((err) => {
      console.log('Erro ', err);
      message.error('Ocorreu um erro na requisição. Por favor, tente novamente mais tarde');
      setLoading(false);
    });
  }

  const columns = [
    {
      title: 'Nome',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Grupo',
      dataIndex: 'companyGroup',
      key: 'group',
      render: v => v?.name,
    },
  ];

  useEffect(() => {
    fetchData(1);
  }, []);

  return (
    <div className="CompanyList">
      <Spin
        tip="Carregando"
        spinning={loading}
      >
        <Button
          className="btn-primary btn-add-new"
          onClick={() => history.push('/empresas/cadastrar')}
        >
          Cadastrar novo
        </Button>
        <Table dataSource={listData} columns={columns} pagination={false} rowKey="id" />
        {/* <Pagination */}
        {/*  className="pagination-container" */}
        {/*  onChange={(page) => { fetchData(page); }} */}
        {/*  defaultCurrent={1} */}
        {/*  current={paginationInfo.current} */}
        {/*  pageSize={paginationInfo.pageSize} */}
        {/*  total={paginationInfo.total} */}
        {/* /> */}
      </Spin>
    </div>
  );
}
