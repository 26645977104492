import React from 'react';
import PropTypes from 'prop-types';
import './ExamplePage.scss';

export default function ExamplePage() {
  return (
    <div className="ExamplePage">
      Página de exemplo
    </div>
  );
}

ExamplePage.propTypes = {
};

ExamplePage.defaultProps = {
};
