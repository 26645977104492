import React, { useEffect, useState } from 'react';
import { Button, Col, message, Row, Spin, Table } from 'antd';
import PieChart from '../pieChart/PieChart';
import { useRouteMatch } from 'react-router-dom';
import { RadialChart } from 'react-vis';
import { get } from '../../../dataSource/data_repository';
import moment from 'moment';
import './ReportChartDetails.scss';
import { getFormattedNumber } from '../../../utils/format';

// const deliveredResult = [
//   { name: 'Abriram', quantity: 29, color: '#d4eb55' },
//   { name: 'Não abriram', quantity: 78, color: '#c0d741' },
// ];
//
// const openedResult = [
//   { name: 'Clicaram', quantity: 25, color: '#84b5c0' },
//   { name: 'Não clicaram', quantity: 4, color: '#60919c' },
// ];
//
// const sentResult = [
//   { name: 'Soft bounces', quantity: 1941, color: '#fbc77c' },
//   { name: 'Hard bounces', quantity: 130, color: '#fab757' },
//   { name: 'Entregues', quantity: 107, color: '#faa832' },
// ];

const SAFRA_ID = 7;

export default function ReportChartDetails() {
  const [loadingData, setLoadingData] = useState(true);
  const [listData, setListData] = useState([]);
  const [date, setDate] = useState('');
  const [quantity, setQuantity] = useState('');
  const [type, setType] = useState('');
  const { id } = useRouteMatch().params;

  const [statusDataWhatsapp, setStatusDataWhatsapp] = useState([]);
  const [sentDataWhatsapp, setSentDataWhatsapp] = useState([]);

  const [deliveredDataEmail, setDeliveredDataEmail] = useState([]);
  const [openedDataEmail, setOpenedDataEmail] = useState([]);
  const [sentDataEmail, setSentDataEmail] = useState([]);

  const [reportData, setReportData] = useState({});
  const [responseData, setResponseData] = useState('');

  const createRenderObject = (item, totalSent) => ({
    ...item,
    angle: item.quantity,
    label: `${item.quantity} (${getFormattedNumber((100 * item.quantity) / totalSent, 1)}%)`,
  });

  function fillWhatsAppData(reportDetailChart) {
    const contadorStatusResult = [
      { name: 'Entregue', quantity: reportDetailChart.contadorStatus, color: '#d4eb55' },
      { name: 'Não entregue', quantity: reportDetailChart.enviado - reportDetailChart.contadorStatus, color: '#c0d741' },
    ];

    const contadorEnviadoResult = [
      { name: 'Sim', quantity: reportDetailChart.contadorEnviado, color: '#84b5c0' },
      { name: 'Não', quantity: reportDetailChart.enviado - reportDetailChart.contadorEnviado, color: '#60919c' },
    ];


    const contadorStatusTotal = contadorStatusResult.reduce((total, item) => (total + item.quantity), 0);
    setStatusDataWhatsapp(contadorStatusResult.map(x => createRenderObject(x, contadorStatusTotal)));

    const contadorEnviadoTotal = contadorEnviadoResult.reduce((total, item) => (total + item.quantity), 0);
    setSentDataWhatsapp(contadorEnviadoResult.map(x => createRenderObject(x, contadorEnviadoTotal)));
  }

  function fillSMSData(reportDetailChart) {
  }

  function fillEmailData(reportDetailChart) {
  console.log('EMAIL');
    const deliveredResult = [
      { name: 'Abriram', quantity: reportDetailChart.contadorAberto, color: '#d4eb55' },
      { name: 'Não abriram', quantity: reportDetailChart.enviado - reportDetailChart.contadorAberto, color: '#c0d741' },
    ];

    const openedResult = [
      { name: 'Clicaram', quantity: reportDetailChart.contadorClique, color: '#84b5c0' },
      { name: 'Não clicaram', quantity: reportDetailChart.enviado - reportDetailChart.contadorClique, color: '#60919c' },
    ];

    const sentResult = [
      { name: 'Soft bounces', quantity: reportDetailChart.softBounced, color: '#fbc77c' },
      { name: 'Hard bounces', quantity: reportDetailChart.hardBounced, color: '#fab757' },
      { name: 'Entregues', quantity: reportDetailChart.enviado - reportDetailChart.softBounced - reportDetailChart.hardBounced, color: '#faa832' },
    ];


    const totalDelivered = deliveredResult.reduce((total, item) => (total + item.quantity), 0);
    setDeliveredDataEmail(deliveredResult.map(x => createRenderObject(x, totalDelivered)));

    const totalOpenedEmail = openedResult.reduce((total, item) => (total + item.quantity), 0);
    setOpenedDataEmail(openedResult.map(x => createRenderObject(x, totalOpenedEmail)));

    const totalSentEmail = sentResult.reduce((total, item) => (total + item.quantity), 0);
    setSentDataEmail(sentResult.map(x => createRenderObject(x, totalSentEmail)));
  }

  function fillSafraData(reportDetailChart) {
    console.log('SAFRA');
  }

  async function fetchData() {
    get({ url: `/report/detailChart?id=${id}` }).then((response) => {
      setLoadingData(false);
      console.log('response ', response);

      setResponseData(JSON.stringify(response));

      if (!response.error) {
//         setListData(response.listReportDetail.map((v) => ({
//           ...v,
//           campaign: response.report.campaign,
//           date: moment(response.report?.date, 'yyyy-MM-DD').format('DD/MM/yyyy'),
//         })));
//         setDate(moment(response.report?.date, 'yyyy-MM-DD').format('DD/MM/yyyy'));
//         setQuantity(response.report?.quantity);
//         setType(response.report?.type);
        const { reportDetailChart } = response;
        const { report } = response;

        console.log('report ', report);

        setReportData({
          type: report.type,
          id: report.id,
          company: report?.company?.name,
          companyId: report?.company?.id,
          campaign: report.campaign,
          base: report?.company?.name,
          date: moment(report?.date, 'yyyy-MM-DD').format('DD/MM/yyyy'),
        });

        switch (report.type) {
          case 'WhatsApp':
            fillWhatsAppData(response.reportDetailChartWhatsApp);
            break;
          case 'SMS':
            fillSMSData(response.reportDetailChartSMS);
            break;
          case 'E-mail':
          console.log('report.companyId ', report.company.id);
            if (report.company.id != SAFRA_ID) {
              fillEmailData(response.reportDetailChartEmail);
            } else {
              fillSafraData(response.reportDetailChartSafra);
            }
            break;
          default:
            console.log('');
        }

      } else {
        message.error('Ocorreu um erro ao buscar os dados');
      }
      setLoadingData(false);
    }).catch((err) => {
      console.log('Erro ', err);
      message.error('Ocorreu um erro ao buscar os dados');
      setLoadingData(false);
    });
  }

  useEffect(() => {
    if (loadingData) {
      fetchData();
    }
    document.title = `Relatório ${id}`;
  }, []);

  return (
    <div className="ReportChartDetails">
      <Spin
        tip="Carregando"
        spinning={loadingData}
      >
        <div className="report-chart-container">
          <div id="report-document-chart">
            <div className="header-container">
              <div className="item">
                <div className="title">ID: </div>
                <div className="text">{reportData.id}</div>
              </div>
              <div className="item">
                <div className="title">Empresa: </div>
                <div className="text">{reportData.company}</div>
              </div>
              <div className="item">
                <div className="title">Campanha: </div>
                <div className="text">{reportData.campaign}</div>
              </div>
            </div>

            {reportData.type == 'E-mail' && reportData.companyId != SAFRA_ID && (
              <div className="pie-chart-container">
                <PieChart title="Dos entregues" data={deliveredDataEmail} />
                <PieChart title="Das aberturas" data={openedDataEmail} />
                <PieChart title="Dos enviados" data={sentDataEmail} />
              </div>
            )}

            {reportData.type == 'WhatsApp' && (
              <>

                <div className="footer-container">
                  <div className="item">
                    <div className="title">Base: </div>
                    <div className="text">{reportData.base}</div>
                  </div>
                  <div className="item">
                    <div className="title">Data: </div>
                    <div className="text">{reportData.date}</div>
                  </div>
                  <div className="item">
{/*                     <div className="title">Texto: </div> */}
{/*                     <div className="text">{reportData.company}</div> */}
                  </div>
                </div>

                <div className="pie-chart-container">
                  <PieChart title="Status" data={statusDataWhatsapp} />
                  <PieChart title="Enviado" data={sentDataWhatsapp} />
                </div>
              </>
            )}

          </div>
          <Button
            id="print-button"
            style={{ marginTop: 20 }}
            onClick={() => {
             document.getElementById("root").classList.add("printing-report");
             window.print();
             document.getElementById("root").classList.remove("printing-report");
            }}
          >
            Imprimir
          </Button>
        </div>
      </Spin>
    </div>
  );
}
