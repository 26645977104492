import React, { useEffect, useState } from 'react';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import {
  Form, Button, Input, Row, Col, Select, message,
} from 'antd';
import './UserRegister.scss';
import { get, post, put } from '../../dataSource/data_repository';
import { selectStringFilter } from '../../utils/forms';

export default function UserRegister() {
  const history = useHistory();
  const location = useLocation();
  const [form] = Form.useForm();
  const [listCompanies, setListCompanies] = useState([]);
  const [listRoles, setListRoles] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [user, setUser] = useState(null);
  const [editPassword, setEditPassword] = useState(true);
  const [initialValues, setInitialValues] = useState({
    name: '',
    email: '',
    company: [],
    role: 1,
  });
  // const [isLeavingByCancelButton, setLeavingByCancelButton] = useAsyncState(false);
  const { id } = useRouteMatch().params;

  const handleRegister = async () => {
    setLoading(true);

    const params = {
      name: form.getFieldValue('name'),
      email: form.getFieldValue('email'),
      companies: form.getFieldValue('company'),
      roleId: form.getFieldValue('role'),
      password: form.getFieldValue('password'),
      status: 'ACTIVATED',
    };

    setLoading(true);

    post({ url: '/user', params }).then((response) => {
      setLoading(false);
      if (!response.error) {
        message.success('Usuário criado com sucesso');
        history.push('/usuarios/listar');
      } else {
        message.error('Ocorreu um erro ao cadastrar o usuário');
      }
    }).catch((err) => {
      message.error('Ocorreu um erro, tente novamente mais tarde');
      setLoading(false);
    });

  };

  const handleEdit = async () => {

    setLoading(true);

    const params = {
      name: form.getFieldValue('name'),
      email: form.getFieldValue('email'),
      companies: form.getFieldValue('company'),
      roleId: form.getFieldValue('role'),
      status: 'ACTIVATED',
    };

    if (form.getFieldValue('password')) {
      params.password = form.getFieldValue('password');
    }

    setLoading(true);

    put({ url: `/user/${id}`, params }).then((response) => {
      setLoading(false);
      if (!response.error) {
        message.success('Usuário editado com sucesso');
        history.push('/usuarios/listar');
      } else {
        message.error('Ocorreu um erro ao editar o usuário');
      }
    }).catch((err) => {
      message.error('Ocorreu um erro, tente novamente mais tarde');
      setLoading(false);
    });

  };

  const handleSubmit = async () => {
    if (user) {
      handleEdit();
    } else {
      handleRegister();
    }
  };

  useEffect(() => {
    setLoading(true);
    get({ url: '/company' }).then((response) => {
      setLoading(false);
      if (!response.error) {
        setListCompanies(response.companies);
      } else {
        message.error('Ocorreu um erro ao buscar os dados da lista de empresas');
      }
    }).catch((err) => {
      message.error('Ocorreu um erro ao buscar os dados da lista de empresas');
      setLoading(false);
    });
    setLoading(true);
    get({ url: '/role/listAll' }).then((response) => {
      setLoading(false);
      if (!response.error) {
        setListRoles(response.roles);
        console.log('response ', response);
      } else {
        message.error('Ocorreu um erro ao buscar os dados da lista de perfis');
      }
    }).catch((err) => {
      message.error('Ocorreu um erro ao buscar os dados da lista de perfis');
      setLoading(false);
    });
    if (location.pathname.startsWith('/usuarios/editar/')) {
      get({ url: `/user/${id}` }).then((response) => {
        setLoading(false);
        if (!response.error) {
          console.log('response ', response);
          setEditPassword(false);
          setUser(response.user);
          setInitialValues({
            name: response.user.name,
            email: response.user.email,
            company: response.user.companies.map(v => v.id),
            role: response.user.roleId,
          });
          form.resetFields();
        } else {
          message.error('Ocorreu um erro ao buscar os dados do usuário');
        }
      }).catch((err) => {
        message.error('Ocorreu um erro ao buscar os dados do usuário');
        setLoading(false);
      });
    }
  }, []);


  // const showPromptOnLeave = () => {
  //   const params = {
  //     oldPassword: form.getFieldValue('oldPassword'),
  //     password: form.getFieldValue('password'),
  //     confirmPassword: form.getFieldValue('confirmPassword'),
  //   };
  //
  //   if ((params.name === undefined || params.name.length === 0)
  //     && (params.companyId === undefined || params.companyId.length === 0)
  //   ) {
  //     return false;
  //   } else {
  //     return true;
  //   }
  // };
  //
  // const handleBack = () => {
  //   if (showPromptOnLeave()) {
  //     Modal.confirm({
  //       title: UserRegisterMessages.updatedDataWillBeLost,
  //       content: UserRegisterMessages.areYouSureWantCancel,
  //       okText: UserRegisterMessages.yesCancel,
  //       cancelText: UserRegisterMessages.no,
  //       autoFocusButton: null,
  //       icon: <WarningTwoTone twoToneColor={colors.warning} />,
  //       onOk: async () => {
  //         await setLeavingByCancelButton(true);
  //         history.push(routesPaths.root);
  //       },
  //     });
  //   } else {
  //     history.push(routesPaths.root);
  //   }
  // };

  return (
    <div className="UserRegister">
      {/*<Prompt*/}
      {/*  message={*/}
      {/*    (location) => {*/}
      {/*      if (location.pathname != routesPaths.UserRegister) {*/}
      {/*        if (showPromptOnLeave() && !isLeavingByCancelButton) {*/}
      {/*          return `${UserRegisterMessages.areYouSureWantCancel}\n${UserRegisterMessages.updatedDataWillBeLost}`;*/}
      {/*        }*/}
      {/*      }*/}
      {/*    }*/}
      {/*  }*/}
      {/*/>*/}

      <Form
        form={form}
        className="UserRegisterForm"
        onFinish={() => handleSubmit()}
        layout="vertical"
        initialValues={initialValues}
      >
        <Row gutter={24}>
          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <Form.Item
              label="Nome"
              name="name"
              rules={[{ required: true, message: 'O nome é obrigatório' }]}
            >
              <Input />
            </Form.Item>
          </Col>

          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <Form.Item
              name="email"
              label="E-mail"
              rules={[
                { type: 'email', message: 'Por favor, insira um e-mail válido' },
                { required: true, message: 'O campo é obrigatório' },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>

          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <Form.Item
              label="Empresa"
              name="company"
              rules={[{ required: true, message: 'Por favor, selecione uma opção' }]}
            >
              <Select
                mode="multiple"
                allowClear
                filterOption={selectStringFilter}
                style={{ minWidth: 80, width: '100%' }}
              >
                {
                  listCompanies.map(v => (
                    <Select.Option key={v.id} value={v.id}>
                      {v.companyGroup?.name ? `${v.name} - ${v.companyGroup?.name}` : v.name}
                    </Select.Option>
                  ))
                }
              </Select>
            </Form.Item>
          </Col>

          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <Form.Item
              label="Tipo"
              name="role"
              rules={[{ required: true, message: 'Por favor, selecione uma opção' }]}
            >
              <Select style={{ minWidth: 80, width: '100%' }}>
                {
                  listRoles.map(v => (
                    <Select.Option key={v.id} value={v.id}>
                      {v.name}
                    </Select.Option>
                  ))
                }
              </Select>
            </Form.Item>
          </Col>

          {user && (
            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
              <br />
              <br />
              {
                editPassword ? (
                  <Button onClick={() => setEditPassword(false)}>Manter senha antiga</Button>
                ) : (
                  <Button onClick={() => setEditPassword(true)}>Editar senha</Button>
                )
              }
            </Col>
          )}

          {editPassword && (
              <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                <Form.Item
                  label="Senha"
                  name="password"
                  rules={[{ required: true, message: 'A senha é obrigatória' }]}
                >
                  <Input.Password />
                </Form.Item>
              </Col>
          )}

        </Row>


        <div className="actions-container">
          <Button
            className="btn-secondary btn-action"
            // onClick={() => handleBack()}
            onClick={() => history.push('/usuarios/listar')}
          >
            Cancelar
          </Button>

          <Button
            className="btn-primary btn-action"
            type="primary"
            htmlType="submit"
            loading={isLoading}
          >
            Salvar
          </Button>
        </div>
      </Form>
    </div>
  );
}
