// React Imports
import React, { useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';

// External Packages

// Ant Design, Icons and Stylesheet
import { Button, Form } from 'antd';
import './ResetPassword.scss';

// Assets, Constants and Resources
import { ResetPasswordCodes } from '../../utils/constants/response-codes';
import { ResetPasswordMessages } from '../../assets/strings/strings';

// Services, Requests and Utilities
import { resetPassword } from '../../dataSource/auth_requests';
import BaseFormComponent from '../../components/base_form_component/BaseFormComponent';
import NewPassword from '../../components/newPassword/NewPassword';
import MessageUtils from '../../utils/message-utils';
import { routesPaths } from '../../routes/RoutesPaths';

// Components
import HyperlinkButton from '../../components/hyperlinkButton/HyperlinkButton';

const ResetPasswordPage = () => {
  const history = useHistory();

  const { token } = useRouteMatch().params;

  const [isLoading, setLoading] = useState(false);

  const [form] = Form.useForm();

  async function handleFinish() {
    const password = form.getFieldValue('password');

    setLoading(true);

    const response = await resetPassword({
      token,
      newPassword: password,
    });

    setLoading(false);

    switch (response.code) {
      case ResetPasswordCodes.SUCCESS:
        MessageUtils.success({
          message: ResetPasswordMessages.passwordResetSuccessfully,
          description: ResetPasswordMessages.youCanLogiNow,
        });
        break;

      case ResetPasswordCodes.error.INVALID_TOKEN:
        MessageUtils.error({
          description: ResetPasswordMessages.notFoundResetPasswordToken,
        });
        break;

      case ResetPasswordCodes.error.TOKEN_NOT_FOUND:
        MessageUtils.error({
          description: ResetPasswordMessages.invalidTokenResetPassword,
        });
        break;

      default:
        MessageUtils.error();
        break;
    }
  }

  return (
    <div className="ResetPassword">
      <BaseFormComponent>
        <div>
          <Form
            form={form}
            onFinish={() => handleFinish()}
            layout="vertical"
            className="form"
            noValidate
          >
            <NewPassword
              form={form}
              showLabel
            />

            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                className="PrimaryButton"
                loading={isLoading}
              >
                {ResetPasswordMessages.confirmButton}
              </Button>

            </Form.Item>

            <HyperlinkButton onClick={() => history.push(routesPaths.root)}>
              {ResetPasswordMessages.backToLogin}
            </HyperlinkButton>

          </Form>
        </div>
      </BaseFormComponent>
    </div>
  );
};

export default ResetPasswordPage;
