import React, { useEffect, useState } from 'react';
import {Col, message, Row, Spin, Button, Table, Form, Input, InputNumber, Select} from 'antd';
import './ExampleFilterList.scss';
import { sampleGET } from '../../../dataSource/data_repository';
import { ExampleFilterListMessages } from '../../../assets/strings/strings';

const arrayValores = [
  { id: 1, name: 'Nome 1' },
  { id: 2, name: 'Nome 2' },
  { id: 3, name: 'Nome 3' },
  { id: 4, name: 'Nome 4' },
  { id: 5, name: 'Nome 5' },
  { id: 6, name: 'Nome 6' },
  { id: 7, name: 'Nome 7' },
];

const dataSource = [
  {
    key: '1',
    name: 'Mike',
    age: 32,
    address: '10 Downing Street',
  },
  {
    key: '2',
    name: 'John',
    age: 42,
    address: '10 Downing Street',
  },
];

const columns = [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'Age',
    dataIndex: 'age',
    key: 'age',
  },
  {
    title: 'Address',
    dataIndex: 'address',
    key: 'address',
  },
];

const initialData = {
  textField: '',
  numberField: null,
  selectField: 1,
};

export default function ExampleFilterList() {
  const [form] = Form.useForm();
  const [loadingData, setLoadingData] = useState(true);
  const [listData, setListData] = useState([]);

  async function cleanData() {
    // reset fields
    form.resetFields();
    fetchData();
  }

  function getFilterString() {
    let filterString = '';
    if (form.getFieldValue('textField')) {
      filterString = `${filterString}&textField=${form.getFieldValue('textField')}`;
    }
    if (form.getFieldValue('numberField')) {
      filterString = `${filterString}&numberField=${form.getFieldValue('numberField')}`;
    }
    if (form.getFieldValue('selectField')) {
      filterString = `${filterString}&selectField=${form.getFieldValue('selectField')}`;
    }
    return filterString;
  }

  async function fetchData() {
    const filterString = getFilterString();
    console.log('filterString ', filterString);
    sampleGET({ url: `/?page=1${filterString}` }).then((response1) => {
      setLoadingData(false);
      console.log('response1 ', response1);
      const response = {
        error: false,
      };
      if (!response.error) {
        setListData(dataSource);
      } else {
        message.error(ExampleFilterListMessages.messageErrorLoadingData);
      }
      setLoadingData(false);
    }).catch((err) => {
      console.log('Erro ', err);
      message.error(ExampleFilterListMessages.messageGeneralRequestError);
      setLoadingData(false);
    });
  }

  useEffect(() => {
    if (loadingData) {
      fetchData();
    }
  }, []);

  return (
    <div className="ExampleFilterList">
      <Spin
        tip={ExampleFilterListMessages.spinLoading}
        spinning={loadingData}
      >
        <Form
          form={form}
          onFinish={fetchData}
          initialValues={initialData}
          labelAlign="left"
          layout="vertical"
          noValidate // disable HTML5 validation
        >
          <Row gutter={24} className="filters-container">
            <Col xs={{ span: 24 }} lg={{ span: 6 }}>
              <Form.Item
                label="Texto"
                name="textField"
                rules={[ { max: 200, message: ExampleFilterListMessages.validationCharactersMaximum200 } ]}
              >
                <Input placeholder="Campo de texto" />
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} lg={{ span: 6 }}>
              <Form.Item
                label="Number"
                name="numberField"
                rules={[]}
              >
                <InputNumber placeholder="Campo númerico" style={{ width: '100%' }} min={0} max={30} />
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} lg={{ span: 6 }}>
              <Form.Item
                label="Select"
                name="selectField"
                rules={[ ]}
              >
                <Select style={{ minWidth: 80, width: '100%' }}>
                  {
                    arrayValores.map(v => (
                      <Option key={v.id} value={v.id}>
                        {v.name}
                      </Option>
                    ))
                  }
                </Select>
              </Form.Item>
            </Col>
            <Col xs={{ span: 12 }} lg={{ span: 3 }}>
              <Button
                disable={loadingData}
                className="SecondaryButton button-filter"
                type="primary"
                onClick={cleanData}
              >
                {ExampleFilterListMessages.buttomClean.toUpperCase()}
              </Button>
            </Col>
            <Col xs={{ span: 12 }} lg={{ span: 3 }}>
              <Button
                disable={loadingData}
                className="PrimaryButton button-filter"
                type="primary"
                htmlType="submit"
                loading={loadingData}
              >
                {ExampleFilterListMessages.buttomFilter.toUpperCase()}
              </Button>
            </Col>
          </Row>
        </Form>
        <Table dataSource={listData} columns={columns} pagination={false} />
      </Spin>
    </div>
  );
}
