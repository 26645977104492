import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Form, Button, Input, Row, Col, message, Select } from 'antd';
import './CompanyRegister.scss';
import { get, post } from '../../dataSource/data_repository';

export default function CompanyRegister() {
  const history = useHistory();
  const [form] = Form.useForm();
  const [isLoading, setLoading] = useState(false);
  const [listCompanyGroups, setListCompanyGroups] = useState([]);

  const handleSubmit = async () => {
    setLoading(true);

    post({
      url: '/company',
      params: { name: form.getFieldValue('name'), companyGroupId: form.getFieldValue('companyGroupId') },
    }).then((response) => {
      setLoading(false);
      if (!response.error) {
        message.success('Empresa criada com sucesso');
        history.push('/empresas/listar');
      } else {
        message.error('Ocorreu um erro ao cadastrar a empresa');
      }
    }).catch((err) => {
      message.error('Ocorreu um erro, tente novamente mais tarde');
      setLoading(false);
    });

  };

    useEffect(() => {
      setLoading(true);
      get({ url: '/companyGroup' }).then((response) => {
        setLoading(false);
        if (!response.error) {
          setListCompanyGroups(response.companyGroups);
        } else {
          message.error('Ocorreu um erro ao buscar os dados da lista de grupos de empresas');
        }
      }).catch((err) => {
        message.error('Ocorreu um erro ao buscar os dados da lista de grupos de empresas');
        setLoading(false);
      });

//       setLoading(true);
//       if (location.pathname.startsWith('/usuarios/editar/')) {
//         get({ url: `/user/${id}` }).then((response) => {
//           setLoading(false);
//           if (!response.error) {
//             console.log('response ', response);
//             setEditPassword(false);
//             setUser(response.user);
//             setInitialValues({
//               name: response.user.name,
//               email: response.user.email,
//               company: response.user.companies.map(v => v.id),
//               role: response.user.roleId,
//             });
//             form.resetFields();
//           } else {
//             message.error('Ocorreu um erro ao buscar os dados do usuário');
//           }
//         }).catch((err) => {
//           message.error('Ocorreu um erro ao buscar os dados do usuário');
//           setLoading(false);
//         });
//       }
    }, []);

  return (
    <div className="CompanyRegister">
      <Form
        form={form}
        className="CompanyRegisterForm"
        onFinish={() => handleSubmit()}
        layout="vertical"
      >
        <Row gutter={24}>
          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <Form.Item
              label="Nome"
              name="name"
              rules={[{ required: true, message: 'O nome é obrigatório' }]}
            >
              <Input />
            </Form.Item>
          </Col>

          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <Form.Item
              label="Grupo"
              name="companyGroupId"
            >
              <Select
                allowClear
                style={{ minWidth: 80, width: '100%' }}
              >
                {
                  listCompanyGroups.map(v => (
                    <Select.Option key={v.id} value={v.id}>
                      {v.name}
                    </Select.Option>
                  ))
                }
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <div className="actions-container">
          <Button
            className="btn-secondary btn-action"
            // onClick={() => handleBack()}
            onClick={() => history.push('/empresas/listar')}
          >
            Cancelar
          </Button>

          <Button
            className="btn-primary btn-action"
            type="primary"
            htmlType="submit"
            loading={isLoading}
          >
            Salvar
          </Button>
        </div>
      </Form>
    </div>
  );
}
