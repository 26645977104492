import React, { useEffect, useState } from 'react';
import { Col, Form, message, Input, Row, Select, Spin, Button } from 'antd';
import { useHistory, useRouteMatch } from 'react-router-dom';
import './ExampleForm4.scss';
import { sampleGET, fetchCEP } from '../../../dataSource/data_repository';
import { ExampleForm4Messages } from '../../../assets/strings/strings';
import { formatCEP } from "../../../utils/format";
import { STATES } from "../../../utils/constants/constants";

const { Option } = Select;

export default function ExampleForm4() {
  const history = useHistory();
  const [form] = Form.useForm();
  const [initialData, setInitialData] = useState({});
  const [loading, setLoading] = useState(false);

  const { id } = useRouteMatch().params;

  useEffect(() => {
    sampleGET({ url: `/${id}` }).then((response1) => {
      setLoading(false);
      console.log('response1 ', response1);
      const response = {
        error: false,
      };
      if (!response.error) {
        setInitialData({});
        form.resetFields();
      } else {
        message.error(ExampleForm4Messages.messageErrorLoadingData);
      }
    }).catch((err) => {
      console.log('Erro ', err);
      message.error(ExampleForm4Messages.messageGeneralRequestError);
      setLoading(false);
    });
  }, []);

  async function handleSubmit() {
    setLoading(true);
    console.log('FieldsValue ', form.getFieldsValue());
    sampleGET({ url: '/form-url' }).then((response1) => {
      setLoading(false);
      console.log('response1 ', response1);
      const response = {
        error: false,
      };
      if (!response.error) {
        message.success(ExampleForm4Messages.messageSuccessfullySubmittedForm);
        history.push('/');
      } else {
        message.error(ExampleForm4Messages.messageErrorSendingData);
      }
    }).catch((err) => {
      console.log('Erro ', err);
      message.error(ExampleForm4Messages.messageGeneralRequestError);
      setLoading(false);
    });
  }

  async function autocompleteByCEP(cep) {
    const numbers = cep.replace(/\D/g, ''); // Only numbers
    if (numbers.length === 8) {
      fetchCEP(cep).then((response) => {
        if (!response.erro) {
          const data = {};
          if (response.uf !== '') {
            data.stateField = response.uf;
          }
          if (response.localidade !== '') {
            data.cityField = response.localidade;
          }
          if (response.logradouro !== '') {
            data.addressField = response.logradouro;
          }
          if (response.bairro !== '') {
            data.neighborhoodField = response.bairro;
          }
          form.setFieldsValue(data);
        } else {
          message.error(ExampleForm4Messages.messageErrorSendingData);
        }
      }).catch((err) => {
        console.log('Erro ', err);
        message.error(ExampleForm4Messages.messageGeneralRequestError);
        setLoading(false);
      });
    }
  }

  return (
    <div className="ExampleForm4">
      <Spin
        tip={ExampleForm4Messages.spinLoading}
        spinning={loading}
      >
        <Form
          form={form}
          onFinish={handleSubmit}
          initialValues={initialData}
          labelAlign="left"
          layout="vertical"
          noValidate // disable HTML5 validation
        >
          <Row gutter={24}>
            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
              <Form.Item
                label={ExampleForm4Messages.cepField}
                name="cepField"
                rules={[
                  { required: true, message: ExampleForm4Messages.validationRequired },
                ]}
                getValueFromEvent={event => formatCEP(event.target.value)}
                onChange={event => autocompleteByCEP(event.target.value)}
              >
                <Input placeholder="" />
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
              <Form.Item
                label={ExampleForm4Messages.stateField}
                name="stateField"
                rules={[{ required: true, message: ExampleForm4Messages.validationRequired }]}
              >
                <Select style={{ minWidth: 80, width: '100%' }}>
                  {
                    STATES.map(v => (
                      <Option key={v.initials} value={v.initials}>
                        {v.state}
                      </Option>
                    ))
                  }
                </Select>
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
              <Form.Item
                label={ExampleForm4Messages.cityField}
                name="cityField"
                rules={[
                  { required: true, message: ExampleForm4Messages.validationRequired },
                ]}
              >
                <Input placeholder="" />
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
              <Form.Item
                label={ExampleForm4Messages.addressField}
                name="addressField"
                rules={[
                  { required: true, message: ExampleForm4Messages.validationRequired },
                ]}
              >
                <Input placeholder="" />
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
              <Form.Item
                label={ExampleForm4Messages.numberField}
                name="numberField"
                rules={[
                  { required: true, message: ExampleForm4Messages.validationRequired },
                ]}
              >
                <Input placeholder="" />
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
              <Form.Item
                label={ExampleForm4Messages.neighborhoodField}
                name="neighborhoodField"
                rules={[
                  { required: true, message: ExampleForm4Messages.validationRequired },
                ]}
              >
                <Input placeholder="" />
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
              <Form.Item
                label={ExampleForm4Messages.complementField}
                name="complementField"
                rules={[]}
              >
                <Input placeholder="" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24} className="actions-container">
            <Col
              lg={{ offset: 10, span: 14 }}
              md={{ span: 24 }}
              xs={{ span: 24 }}
            >
              <Button
                className="SecondaryButton cancel"
                disable={loading}
                onClick={() => { history.push('/'); }}
              >
                {ExampleForm4Messages.buttomCancel.toUpperCase()}
              </Button>

              <Button
                disable={loading}
                className="PrimaryButton save"
                type="primary"
                htmlType="submit"
                loading={loading}
              >
                {ExampleForm4Messages.buttomSave.toUpperCase()}
              </Button>
            </Col>
          </Row>
        </Form>
      </Spin>
    </div>
  );
}
