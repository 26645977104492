import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { routesPaths } from '../../routes/RoutesPaths';
import { useHistory } from 'react-router-dom';

const InitialPage = (props) => {
  const { location } = props;
  const history = useHistory();

  useEffect(() => {
    history.push('/relatorios');
  }, []);

  return (
    <div>InitialPage</div>
  );
};

InitialPage.propTypes = {
  history: PropTypes.objectOf(PropTypes.any).isRequired,
  location: PropTypes.objectOf(PropTypes.any).isRequired,
};

InitialPage.defaultProps = {};

export default InitialPage;
